import React from 'react'
import Logo from '../components/Logo'

const ImageLogo = () =>{
  return (
    <div className=' flex items-center justify-center bg-slate-900 m-auto mt-9 p-10 h-full'>
        <Logo />
    </div>
  )
}

export default ImageLogo
import React from 'react'
//import logo from './logo.css'
import logoM from '../assest/logoM.png'


export default function Logo({ width = '160px', height = '80px' }){
  return (
    
    <img src={logoM} 
    alt='HomeMarket2uLogo'  
    style={{ height, width }} 
    className=' pb-4 pr-2 customMax420:pl-0 customMin1174:ml-5 custom-1075-1173:ml-2 logoCustom-300-390'
    />

  )
}

// import React, { useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import { FaRegUserCircle } from "react-icons/fa";
// import { Link, Outlet, useNavigate } from 'react-router-dom';
// import ROLE from '../common/role';

// function AdminPanel() {
//     const user = useSelector(state => state?.user?.user)
//     const navigate = useNavigate()


//     useEffect(() =>{
//         if(user?.role !== ROLE.ADMIN && user?.role !== ROLE.BUSINESSOWNER){
//             navigate("/")
//         }      
//     }, [user])

//   return (

//     <div className='min-h-[calc(100vh-120px)] flex customMax-800:mt-[40px]'>
//         <aside className='bg-white min-h-full w-full max-w-60' >
//             <div className=' h-35 flex justify-center items-center customShadow flex-col py-2'>
//                 <div className='flex text-5xl cursor-pointer relative justify-center' >
//                     {
//                     user?.profilePic? (
//                         <img src={user?.profilePic} className='w-20 h-20 rounded-full' alt={user?.name}/>
//                     ) : (
//                         <FaRegUserCircle />
//                     )
//                     }
//                 </div>
//                 <p className='capitalize text-lg font-semibold'>{user?.name}</p>
//                 <p className='text-sm'>{user?.role}</p>
//             </div>

//             {/**navigation */}
//             <div>
//                 <nav className='grid p-4'>
//                     {
//                         user?.role === ROLE.ADMIN && (
//                             <Link to={"all-users"} className='px-2 py-1 hover:bg-slate-100'>All Users</Link>
//                         )
//                     }
//                     <Link to={"all-products"} className='px-2 py-1 hover:bg-slate-100'>All products</Link>
//                 </nav>
//             </div>
            
//         </aside>

//         <main className='w-full h-full p-2'>
//             <Outlet/>
//         </main>
//     </div>
   
//   )
// }

// export default AdminPanel

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaRegUserCircle } from "react-icons/fa";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROLE from '../common/role';

function AdminPanel() {
    const user = useSelector(state => state?.user?.user)
    const navigate = useNavigate()

    // When the user logs in or updates their role, save it to localStorage
    useEffect(() => {
        if (user?.role) {
            localStorage.setItem("userRole", user.role);
        }
    }, [user]);

    useEffect(() => {
        // Get role from Redux or fallback to localStorage
        const role = user?.role || localStorage.getItem("userRole");
    
        if (!role) {
            // Role is not yet available; we do nothing to avoid premature redirect
            return;
        }
    
        if (role !== ROLE.ADMIN && role !== ROLE.BUSINESSOWNER) {
            navigate("/"); // Redirect to home if unauthorized
        }
    }, [user, navigate]);
    


    // useEffect(() => {
    //     if (user?.role !== ROLE.ADMIN && user?.role !== ROLE.BUSINESSOWNER) {
    //         navigate("/")
    //     }else {
    //         // Force a page reload if the user has the correct role
    //         window.location.reload();
    //     }
    // }, [user])

    return (
        <div className='min-h-[calc(100vh-120px)] flex flex-col customMax-800:mt-[40px] md:flex-row'>
            <aside className='bg-white min-h-full w-full md:max-w-60 md:w-1/3 lg:w-1/4 customShadow'>
                <div className='h-35 flex justify-center items-center flex-col py-2'>
                    <div className='flex text-5xl cursor-pointer relative justify-center'>
                        {
                            user?.profilePic ? (
                                <img src={user?.profilePic} className='w-20 h-20 rounded-full' alt={user?.name} />
                            ) : (
                                <FaRegUserCircle />
                            )
                        }
                    </div>
                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                    <p className='text-sm'>{user?.role}</p>
                </div>

                {/* Navigation */}
                <div className='bg-slate-700 md:min-h-[calc(100vh-120px)] '>
                    <nav className='grid p-4 customMax-767:grid-cols-2  text-white gap-2 '>
                        {user?.role === ROLE.ADMIN && (
                            <Link to={"all-users"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-green-300 rounded hover:text-black'>All Users</Link>
                        )}
                        <Link to={"all-products"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-orange-300 rounded hover:text-black '>All products</Link>
                        <Link to={"pending-approval"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-blue-400 rounded hover:text-black '>Pending Approval</Link>
                        <Link to={"rejected-approval"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 rounded  hover:text-black'>Rejected Approval</Link>
                    </nav>
                </div>
            </aside>

            <main className='w-full h-full p-2'>
                <Outlet />
            </main>
        </div>
    )
}

export default AdminPanel;

//check customer's cart for multiple currency
export const hasMultipleCurrencies = (cartItems) => {
    const currencies = cartItems.map(item => item.productId.currency);
    return new Set(currencies).size > 1; // Returns true if there are multiple currencies
};


//if true, pick customer's location
export const fetchCustomerLocation = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const location = await response.json();
        return location; // Assuming you return the needed info from location
    } catch (error) {
        console.error("Error fetching customer location:", error);
        // Handle fallback logic, such as returning a default location
    }
};

export const fetchCustomerCurrency = async () => {
    const location = await fetchCustomerLocation();
    if (location && location.currency) {
        return location.currency; // Ensure you are returning a valid currency
    } else {
        console.error("Invalid location data received:", location);
        return null; // or a default currency
    }
};


// Fetch exchange rates for conversion
export const fetchConversionRate = async (fromCurrency, toCurrency) => {
    const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
    const data = await response.json();
    return data.rates[toCurrency];
};

// Function to calculate the total price with conversion (if necessary)
export const calculateTotal = async (cartItems, customerCurrency) => {
    let totalPrice = 0;

    // Check if cart contains multiple currencies
    const multipleCurrencies = hasMultipleCurrencies(cartItems);

    for (const item of cartItems) {
        const { price, currency } = item.productId;
        let finalPrice = price;

        // Convert the price if there are multiple currencies or if it's different from customer's local currency
        if (multipleCurrencies && currency !== customerCurrency) {
            const conversionRate = await fetchConversionRate(currency, customerCurrency);
            finalPrice = price * conversionRate;
        }

        totalPrice += item.quantity * finalPrice;
    }

    return totalPrice;
};
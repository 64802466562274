// import React, { useState } from "react";

// const CustomDropdown = ({ options, value, onChange }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="relative w-full">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className=" text-white text-base px-3 py rounded max-w-full w-full"
//       >
//         {value || "Select Market"}
//       </button>
//       {isOpen && (
//         <ul className="absolute bg-white text-black max-w-full w-full border rounded shadow-lg mt-1 z-50">
//           {options.map((option) => (
//             <li
//               key={option}
//               className="cursor-pointer px-3 py-2 hover:bg-gray-300 max-w-full"
//               onClick={() => {
//                 onChange(option);
//                 setIsOpen(false);
//               }}
//             >
//               {option}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default CustomDropdown

import React, { useState } from "react";

const CustomDropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-full">
      {/* Dropdown button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-white text-base px-3 rounded w-full"
      >
        {value || "Select Market"}
      </button>

      {/* Dropdown list */}
      <div className="absolute">
        {isOpen && (
        <ul className=" bg-white text-black w-full border rounded shadow-lg mt-1 z-50">
          {options.map((option) => (
            <li
              key={option}
              className="cursor-pointer px-3 py-2 hover:bg-gray-300"
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      </div>
      
    </div>
  );
};

export default CustomDropdown;

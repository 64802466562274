
import React, { useState } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import productCategory from "../helpers/ProductCategory";
import countryList from "../helpers/DeliveryCountry"; // Import the new country list
import { FaCloudUploadAlt } from "react-icons/fa";
import DisplayImage from './DisplayImage';
import { MdDelete } from "react-icons/md";
import summaryApi from '../common';
import { toast } from 'react-toastify';


export const UploadProduct = ({ onClose, fetchProducts }) => {
    const [data, setData] = useState({
        itemName: "",
        price: "",
        category: "",
        productImage : [],
        itemDescription: "",
        DeliveryTo: [],
        currency: 'USD',
        localMarket: "",
    });

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [fullScreeImage, setFullScreenImage] = useState("")
    const [openFullScreenImage, setOpenFullScreenImage] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleMultiSelectChange = (e) => {
        const { name, value, checked } = e.target;

        setData((prevData) => {
            if (name === "DeliveryTo") {
                if (value === "All") {
                    return {
                        ...prevData,
                        [name]: checked ? countryList.map(c => c.value) : []
                    };
                }

                const newSelection = checked
                    ? [...prevData[name], value]
                    : prevData[name].filter(country => country !== value);

                console.log("New selection:", newSelection);

                return {
                    ...prevData,
                    [name]: newSelection
                };
            }

            return {
                ...prevData,
                [name]: value
            };
        });
    };

    // const handleUploadProduct = async(e) =>{
    //     const files = Array.from(e.target.files); // Get all selected files
    //     const uploadImageCloudinary = await Promise.all(
    //         files.map(file => uploadImage(file)) // Upload all files to Cloudinary
    // );

    //     const imageUrls = uploadImageCloudinary.map(response => response.url); // Extract URLs from the responses

    //     setData((preve) =>{
    //         return { 
    //             ...preve,
    //             productImage : [...preve.productImage, ...imageUrls ]
    //         }

    //     })

    //     console.log("upload Image", imageUrls)
       
    // }

    const handleUploadProduct = async (e) => {
        const files = Array.from(e.target.files); // Get all selected files
      
        // Create FormData to send files to the backend
        const formData = new FormData();
        files.forEach((file) => formData.append("files", file));
      
        try {
          // Send the files to the backend for uploading
          const response = await fetch(summaryApi.imageUpload.url, {
            method: summaryApi.imageUpload.method,
            body: formData,
          });
      
          if (!response.ok) {
            throw new Error("Failed to upload images");
          }
      
          // Get the image URLs from the backend
          const imageUrls = await response.json();
      
          // Update state with the new image URLs
          setData((preve) => {
            return {
              ...preve,
              productImage: [...preve.productImage, ...imageUrls],
            };
          });
      
          //console.log("Uploaded Images:", imageUrls);
        } catch (error) {
          console.error("Error uploading images:", error.message);
        }
    };
      

    const handleDeleteProductImage = async(index) =>{
       const newProductImage = [...data.productImage]
       newProductImage.splice(index,1)

       setData((preve) => {
        return {
            ...preve,
            productImage : [...newProductImage]
        }
       })
    }



    const handleSubmit = async(e) =>{
        e.preventDefault()
    
        const productSumbit = await fetch(summaryApi.productUpload.url, {
            method : summaryApi.productUpload.method,
            credentials : "include",
            headers : {
                "content-type" : "application/json"
            },
            body : JSON.stringify(data)
        })

        const dataApi = await productSumbit.json()

        if(dataApi.success){
            toast.success(dataApi?.message)
            fetchProducts();
            onClose()

        }else{
            toast.error(dataApi?.message)
        }

    }

    return (
        <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center'>
            <div className='p-4 rounded max-w-2xl h-full max-h-[80%] bg-white overflow-y-auto mt-[60px] customMax-800:mt-[95px]'>
                <div className='flex justify-between items-center'>
                    <h2 className='font-bold text-lg'>Upload Product</h2>
                    <div className='w-fit text-2xl hover:text-orange-600 cursor-pointer' onClick={onClose}>
                        <IoIosCloseCircleOutline />
                    </div>
                </div>
                <form className='grid p-4 gap-2 font-semibold' onSubmit={handleSubmit}>
                    <label htmlFor='itemName'>Product name:</label>
                    <input
                        type='text'
                        id='itemName'
                        placeholder='Enter item name'
                        name='itemName'
                        value={data.itemName}
                        onChange={handleChange}
                        className='p-2 bg-slate-100 border rounded'
                        required
                    />

                   
                    <label htmlFor='itemDescription'>Product description:</label>
                    <textarea
                        id='itemDescription'
                        placeholder='Enter item description'
                        name='itemDescription'
                        value={data.itemDescription}
                        onChange={handleChange}
                        rows="4" // Customize rows based on how much space you want to show initially
                        className='p-2 bg-slate-100 border rounded w-full'
                        required
                    />


                    <label htmlFor='category'>Select product category:</label>
                    <select
                        id='category'
                        name='category'
                        value={data.category}
                        onChange={handleChange}
                        className='p-2 bg-slate-100 border rounded'
                        required
                    >
                        <option value="">Select Category</option>
                        {productCategory.map((el) => (
                            <option value={el.value} key={el.id}>
                                {el.label}
                            </option>
                        ))}
                    </select>

                    {/* Conditionally render payment options if 'Services' is selected */}
                    {data.category === 'Services' && (
                        <div className='flex mt-3 gap-2 items-center'>
                            <label>Payment option:</label>
                            <select
                                name='paymentOption'
                                value={data.paymentOption}
                                onChange={handleChange}
                                className='p-2 bg-slate-100 border rounded  '
                                required
                            >
                                <option value="">Select Payment Option</option>
                                <option value="hourly">Per Hour</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                    )}


                    <label htmlFor='productImage' className='mt-3'>Product Image</label>
                    <label htmlFor='uploadImageInput'>
                        <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center'>
                            <div className='text-slate-400 flex flex-col justify-center items-center cursor-pointer'>
                                <span className='text-3xl'> <FaCloudUploadAlt/> </span>
                                <p>Upload product</p>
                                <input type='file' id='uploadImageInput' className='hidden' multiple onChange={handleUploadProduct}/>
                            </div>
                        </div>
                    </label>
                    <div>
                        {
                             data?.productImage[0] ? (
                                <div className='flex items-center gap-3'>
                                   
                                    { data.productImage.map((el, index )=>{
                                            return (
                                                <div className='relative group'>
                                                    <img src={el} 
                                                        alt={el} 
                                                        width={80} 
                                                        height={80} 
                                                        className='bg-slate-100 border cursor-pointer'
                                                        required
                                                        onClick={() => {
                                                        setOpenFullScreenImage(true)
                                                        setFullScreenImage(el)
                                                    }} />

                                                    <div className='bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 hidden group-hover:block' onClick={() => handleDeleteProductImage(index)}> 
                                                        <MdDelete /> 
                                                    </div>
                                                </div>
                                                
                                               
                                            )
                                    })}
                                </div>
                            )
                           
                            : 
                            (
                                <p className='text-red-600 text-xs'> *please upload product image* </p>
                            )
                        }
                        
                    </div>

                    <label htmlFor='price'>Product price:</label>
                    <div className='flex items-center'>
                        <select
                            name='currency'
                            value={data.currency}
                            onChange={handleChange}
                            className='p-2 bg-slate-100 border rounded mr-2'
                            required
                        >
                            <option value='USD'>$ (USD)</option>
                            <option value='GBP'>£ (GBP)</option>
                            <option value='INR'>₹ (INR)</option>
                            <option value='NGN'>₦ (NGN)</option>
                            <option value='CAD'>$ (CAD - Canada)</option>
                            <option value='AUD'>$ (AUD - Australia)</option>
                            <option value='EUR'>€ (EURO)</option>
                        </select>
                        <input
                            type='number'
                            id='price'
                            placeholder='Enter item price'
                            name='price'
                            value={data.price}
                            required
                            onChange={handleChange}
                            className='p-2 bg-slate-100 border rounded'
                        />
                    </div>

                    
                    <div className='flex gap-7 items-center mt-3'>
                        <label className=" flex" htmlFor='localMarket'>Seller Local Market:</label>
                        <div>
                            <select
                                id='localMarket'
                                name='localMarket'
                                value={data.localMarket}
                                onChange={handleChange}
                                className='p-2 bg-slate-100 border rounded mr-2'
                                required
                            >
                                <option value=''>Select</option>
                                <option value='India'>India</option>
                                <option value='Nigeria'>Nigeria</option>
                                
                            </select>
                        </div>
                    </div>
                    
                  
                    <label htmlFor='DeliveryTo'>Deliver to:</label>
                    <div className='relative'>
                        <button
                            type='button'
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className='p-2 bg-slate-100 border rounded w-full flex justify-between items-center'
                        >
                            {/* Display selected countries or a placeholder */}
                            <span>{data.DeliveryTo.length > 0 ? data.DeliveryTo.join(', ') : "Select Countries"}</span>

                            {/* Arrow Icon toggles based on dropdown state */}
                            <span className='ml-2'>
                                {isDropdownOpen ? '▲' : '▼'} {/* Up or Down arrow */}
                            </span>
                        </button>

                        {isDropdownOpen && (
                            <div className='absolute z-10 bg-white border rounded shadow-lg mt-1 w-full'>
                                <label className='flex items-center p-2'>
                                    <input
                                        type='checkbox'
                                        value='All'
                                        name='DeliveryTo'
                                        required
                                        onChange={handleMultiSelectChange}
                                        checked={data.DeliveryTo.length === countryList.length}
                                    />
                                    <span className='ml-2'>All</span>
                                </label>

                                {/* Map through the countryList to display options */}
                                {countryList.map((country) => (
                                    <label key={country.value} className='flex items-center p-2'>
                                        <input
                                            type='checkbox'
                                            name='DeliveryTo'
                                            value={country.value}
                                            required
                                            onChange={handleMultiSelectChange}
                                            checked={data.DeliveryTo.includes(country.value)}
                                        />
                                        <span className='ml-2'>{country.label}</span>
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>

                    <button className='px-3 py-2 bg-orange-500 text-white mt-7 rounded-full hover:bg-orange-600'>
                        Upload Product
                    </button>


                    {/* <label htmlFor='DeliveryTo'>Deliver to:</label>
                    <div className='relative'>
                        <button
                            type='button'
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className='p-2 bg-slate-100 border rounded w-full'
                        >
                            {data.DeliveryTo.length > 0 ? data.DeliveryTo.join(', ') : "Select Countries"}
                        </button>
                        {isDropdownOpen && (
                            <div className='absolute z-10 bg-white border rounded shadow-lg mt-1 w-full'>
                                <label className='flex items-center p-2'>
                                    <input
                                        type='checkbox'
                                        value='All'
                                        name='DeliveryTo'
                                        required
                                        onChange={handleMultiSelectChange}
                                        checked={data.DeliveryTo.length === countryList.length}
                                    />
                                    <span className='ml-2'>All</span>
                                </label>
                                {countryList.map((country) => (
                                    <label key={country.value} className='flex items-center p-2'>
                                        <input
                                            type='checkbox'
                                            name='DeliveryTo'
                                            value={country.value}
                                            required
                                            onChange={handleMultiSelectChange}
                                            checked={data.DeliveryTo.includes(country.value)}
                                        />
                                        <span className='ml-2'>{country.label}</span>
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                    <button className='px-3 py-2 bg-orange-500 text-white mt-7 rounded-full hover:bg-orange-600' > Upload Product</button> */}
                </form>
            </div>

            {/**display image full screen */}
        {  openFullScreenImage && (
                <DisplayImage 
                onClose={()=> setOpenFullScreenImage(false)} 
                imgUrl={fullScreeImage}/>
            )}
            
        </div>
    );
};





const backendDomain =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
    : process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;

const summaryApi = {
    signUp : {
        url : `${backendDomain}/api/signup`,
        method :"post"
    },

    otp_verification : {
        url : `${backendDomain}/api/verify-otp`,
        method : "post"
    },

    
    resend_otp : {
        url : `${backendDomain}/api/resend-otp`,
        method : "post"
    },

    signIn : {
        url : `${backendDomain}/api/login`,
        method : "post"
    },

    googleLogin : {
        url : `${backendDomain}/api/auth/google`,
        method : "post"
    },

    changePassword : {
        url : `${backendDomain}/api/change-password`,
        method : 'post'
    },

    verifyChangePassword : {
        url : `${backendDomain}/api/verify-change-password`,
        method : 'post'
    },

    updateUserRole : {
        url : `${backendDomain}/api/update-user-role`,
        method : "post"
    },

    current_user : {
        url : `${backendDomain}/api/user-details`,
        method : "get"
    },

    logout_user : {
        url : `${backendDomain}/api/userLogout`,
        method : "get"
    }, 

    allUsers : {
        url : `${backendDomain}/api/all-user`,
        method : "get"
    }, 

    updateUser : {
        url : `${backendDomain}/api/update-user`,
        method : "post"
    },


    productUpload : {
        url : `${backendDomain}/api/upload-product`,
        method : "post"
    },
    
    allProduct : {
        url : `${backendDomain}/api/get-product`,
        method : "get"
    },

    updateProduct : {
        url : `${backendDomain}/api/update-product`,
        method : "post"
    },

    getCategoryProduct : {
        url : `${backendDomain}/api/get-categoryProduct`,
        method : "post"
    },

    ProductsByCategory : {
        url : `${backendDomain}/api/products-bycategory`,
        method : "post"
    },

    productDetails : {
        url : `${backendDomain}/api/product-details`,
        method : "post"
    },

    addToCardProduct : {
        url : `${backendDomain}/api/addtocart`,
        method : "post"
    },

    addToCartProductCount : { 
        url : `${backendDomain}/api/countAddToCartProduct`,
        method : "get"
    },

    viewAddToCartProducts : { 
        url : `${backendDomain}/api/view-cart-product`,
        method : "get"
    },

    updateCartProducts : { 
        url : `${backendDomain}/api/update-cart-product`,
        method : "post"
    },

    deleteCartProducts : { 
        url : `${backendDomain}/api/delete-cart-product`,
        method : "post"
    },


    searchProduct : { 
        url : `${backendDomain}/api/product-search`,
        method : "get"
    },

    filterProduct : {
        url : `${backendDomain}/api/filter-product`,
        method : "post"
    },

    productApproval : {
        url :`${backendDomain}/api/product-approval`,
        method : 'post'
    },

    pendingApprovalProd : {
        url :`${backendDomain}/api/pending-approval`,
        method : 'get'
    },

    RejectedProduct : {
        url :`${backendDomain}/api/rejected-prod`,
        method : 'get'
    },

    imageUpload : {
        url : `${backendDomain}/api/upload-images`,
        method : 'post'
    }

 
}

export default summaryApi
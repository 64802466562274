import React, { useEffect, useState } from 'react'
import summaryApi from '../common'
import { toast } from "react-toastify";
import moment from 'moment'
import { FaUserEdit } from "react-icons/fa";
import ChangeUserRole from '../components/ChangeUserRole';
import { useNavigate } from 'react-router-dom';


function AllUsers() {
    const [allUser, setAllUser] = useState([])
    const [openUpdateRole, setOpenUpdateRole] = useState(false)
    const [updateUserDetails, setUpdateUserDetails] = useState({
        name : "",
        email : "",
        role : "",
        _id : "",
    })
    const navigate = useNavigate();

    const fetchAllUser = async() =>{
        try{
            const fetchData = await fetch(summaryApi.allUsers.url,{
                method : summaryApi.allUsers.method,
                credentials : "include",
            })
    
            const dataResponse = await fetchData.json()

            if(dataResponse.success){
                setAllUser(dataResponse.data || []);
            } else{
                toast.error(dataResponse.message || "failed to fetch users")
                navigate('/error-fallback');
            }
       
        }catch(error){
            console.error("Error fetching allUsers:", error)
            toast.error("Failed to fetch users");
            navigate('/error-fallback');

        }
         
    }

    useEffect(()=>{
        fetchAllUser()
    },[])

  return (
    <div className='bg-white pb-4'>
        <table className='w-full userTable'>
            <thead>
                <tr className='bg-slate-800 text-white'>
                    <th>Sr</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    allUser.map((el, index) =>{
                        return (
                            <tr key={el._id || index}>
                                <td>{index+1}</td>
                                <td>{el?.name}</td>
                                <td>{el?.email}</td>
                                <td>{el?.role}</td>
                                <td>{moment(el?.createdAt).format('ll')}</td>
                                <td>
                                    <button className='rounded-full hover:bg-green-500 cursor-pointer p-2' 
                                    onClick={() => {
                                        setUpdateUserDetails(el)
                                        setOpenUpdateRole(true)}}>
                                        <FaUserEdit />
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>

           
        </table>

        {
           openUpdateRole && (
            <ChangeUserRole 
            onClose={()=>setOpenUpdateRole(false)} 
            name= {updateUserDetails.name}
            email={updateUserDetails.email}
            role={updateUserDetails.role}
            userId = {updateUserDetails._id}
            callFunc={fetchAllUser}
            />
           )
        }
    </div>
  )
}

export default AllUsers
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import BannerProduct from "../components/BannerProduct";
import { CategoryList } from "../components/CategoryList";
import HorizontalCardProduct from "../components/HorizontalCardProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";
import BannerProduct2 from "../components/BannerProduct2";
import { MarketContext } from "../context/MarketContext";
import LocalMarketModal from "../helpers/LocalMarketSelector";
import ScrollVerticalProductCard from "../components/ScrollVerticalProductCard";
import CookieConsentBanner from "../helpers/CookieConsentBanner";

const Home = () => {
  const { localMarket, updateMarket } = useContext(MarketContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockPage, setBlockPage] = useState(false); // State to block page interactions
  const scrollVerticalRef = useRef();

  const handleScroll = useCallback(() => {
    if (scrollVerticalRef.current) {
      scrollVerticalRef.current.loadMore();
    }
  }, []);

  useEffect(() => {
    if (!localMarket) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [localMarket]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative">
      {/* Overlay for blocking interactions */}
      {blockPage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 pointer-events-none"></div>
      )}

      {/* Modal for local market selection */}
      {isModalOpen && (
        <LocalMarketModal setMarket={updateMarket} closeModal={closeModal} />
      )}

      {/* Page Content */}
      {localMarket && (
        <>
          <CookieConsentBanner setBlockPage={setBlockPage} />
          <BannerProduct />
          <CategoryList localMarket={localMarket} />

          <VerticalCardProduct
            key={`vertical-${localMarket}-1`}
            category={"Electronics"}
            heading={"Best Technology"}
            localMarket={localMarket}
            onScroll={handleScroll}
            ref={scrollVerticalRef}
          />

          <ScrollVerticalProductCard
            key={`verticalScroll-${localMarket}-1`}
            category={["Arts & Crafts", "Accessories", "Services"]}
            heading={"Beauty Arts of Home"}
            localMarket={localMarket}
          />

          <VerticalCardProduct
            key={`vertical-${localMarket}-2`}
            category={"Food"}
            heading={"Food and food stuffs"}
            localMarket={localMarket}
          />
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-1`}
            category={"Food"}
            heading={"Best Food Deal"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
          />
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-2`}
            category={"Electronics"}
            heading={"Best Quality Electronics Best Deal"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
          />
          <BannerProduct2 />
          <VerticalCardProduct
            key={`vertical-${localMarket}-3`}
            category={["Custom-made", "Clothing", "Beauty Care"]}
            heading={"General Goods"}
            localMarket={localMarket}
          />
          <VerticalCardProduct
            key={`vertical-${localMarket}-4`}
            category={["Clothing", "Beauty Care"]}
            heading={"General Goods"}
            localMarket={localMarket}
          />
          <HorizontalCardProduct
            key={`horizontal-${localMarket}-3`}
            category={"Food"}
            heading={"Best Food Deal"}
            localMarket={localMarket}
            ref={scrollVerticalRef}
          />
        </>
      )}
    </div>
  );
};

export default Home;



// import React, { useState, useEffect, useContext,useCallback, useRef } from 'react';
// import BannerProduct from '../components/BannerProduct';
// import { CategoryList } from '../components/CategoryList';
// import HorizontalCardProduct from '../components/HorizontalCardProduct';
// import VerticalCardProduct from '../components/VerticalCardProduct';
// import BannerProduct2 from '../components/BannerProduct2';
// import { MarketContext } from '../context/MarketContext'; // Import the context
// import LocalMarketModal from '../helpers/LocalMarketSelector'; // Import the modal
// import ScrollVerticalProductCard from '../components/ScrollVerticalProductCard';
// import CookieConsentBanner from '../helpers/CookieConsentBanner';

// const Home = () => {
//   const { localMarket, updateMarket } = useContext(MarketContext); // Get market from context
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal
//   const scrollVerticalRef = useRef();


//     // Callback to load more products when scrolling reaches 50%
//     const handleScroll = useCallback(() => {
//       if (scrollVerticalRef.current) {
//           scrollVerticalRef.current.loadMore();
//       }
//     }, []);
  

//   useEffect(() => {
//     // Only open the modal if no market is found in localMarket or localStorage
//     if (!localMarket) {
//       setIsModalOpen(true); // Open the modal
//     } else {
//       setIsModalOpen(false); // Ensure the modal is closed if market exists
//     }
//   }, [localMarket]);

//   const closeModal = () => {
//     setIsModalOpen(false); // Close modal after market is selected
//   };

 

//   return (
//     <div>
//       {/* Display the modal only if no market is selected */}
//       {isModalOpen && (
//         <LocalMarketModal setMarket={updateMarket} closeModal={closeModal} />
//       )}

//       {/* Show the homepage content if the market is selected */}
//       {localMarket && (
//         <>
//           <CookieConsentBanner />
//           <BannerProduct />
//           <CategoryList localMarket={localMarket} /> 

//           <VerticalCardProduct
//             key={`vertical-${localMarket}-1`}
//             category={'Electronics'}
//             heading={'Best Technology'}
//             localMarket={localMarket}
//             onScroll={handleScroll}
//             ref={scrollVerticalRef}
           
//           />

//           <ScrollVerticalProductCard 
//           key={`verticalScroll-${localMarket}-1`}
//           category={["Arts & Crafts", "Accessories", "Services"]}
//           heading={"Beauty Arts of Home"}
//           localMarket={localMarket}
//           />

//           <VerticalCardProduct
//             key={`vertical-${localMarket}-2`}
//             category={'Food'}
//             heading={'Food and food stuffs'}
//             localMarket={localMarket}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-1`}
//             category={'Food'}
//             heading={'Best Food Deal'}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-2`}
//             category={'Electronics'}
//             heading={'Best Quality Electronics Best Deal'}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//           <BannerProduct2 />
//           <VerticalCardProduct
//             key={`vertical-${localMarket}-3`}
//             category={['Custom-made', 'Clothing', 'Beauty Care']}
//             heading={'General Goods'}
//             localMarket={localMarket}
//           />
//           <VerticalCardProduct
//             key={`vertical-${localMarket}-4`}
//             category={['Clothing', 'Beauty Care']}
//             heading={'General Goods'}
//             localMarket={localMarket}
//           />
//           <HorizontalCardProduct
//             key={`horizontal-${localMarket}-3`}
//             category={'Food'}
//             heading={'Best Food Deal'}
//             localMarket={localMarket}
//             ref={scrollVerticalRef}
//           />
//         </>
//       )}
//     </div>
//   );
// };

// export default Home;








// src/App.js

import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import {  ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import summaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';
import { MarketProvider } from './context/MarketContext'; // Import MarketProvider
import ErrorBoundary from './helpers/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';



function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  const currentLocation = useLocation()

  const fetchUserdetails = async () => {
    
    const dataResponse = await fetch(summaryApi.current_user.url, {
      method: summaryApi.current_user.method,
      credentials: 'include',
    });
    const dataApi = await dataResponse.json();
    if (dataApi.success) {
      dispatch(setUserDetails(dataApi.data));
    }
  };

  const fetchUserAddToCartCount = async () => {
    const dataResponse = await fetch(summaryApi.addToCartProductCount.url, {
      method: summaryApi.addToCartProductCount.method,
      credentials: 'include',
    });

    const dataApi = await dataResponse.json();
    setCartProductCount(dataApi?.data?.totalItems);
  };

  useEffect(() => {
    fetchUserdetails();
    fetchUserAddToCartCount();
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [currentLocation.pathname]);

  return (
    <>
      {/* Wrap the entire app with MarketProvider */}
      <ErrorBoundary>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <MarketProvider>
        <Context.Provider
          value={{
            fetchUserdetails,
            cartProductCount,
            fetchUserAddToCartCount,
          }}
        >
          <ToastContainer position="top-center" />
            <Header /> {/* Header will now consume MarketContext */}
            <main className="min-h-[calc(100vh-100px)] pt-16">
            
              <Outlet /> {/* Render home or other routes */}
              
            </main>
            <Footer />
        </Context.Provider>
      </MarketProvider>
      </GoogleOAuthProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;



// import { Outlet } from 'react-router-dom';
// import './App.css';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useEffect, useState } from 'react';
// import summaryApi from './common';
// import Context from './context';
// import { useDispatch } from 'react-redux';
// import { setUserDetails } from './store/userSlice';

// function App() {
//   const dispatch = useDispatch();
//   const [cartProductCount, setCartProductCount] = useState(0);
 

//   const fetchUserdetails = async () => {
//     const dataResponse = await fetch(summaryApi.current_user.url, {
//       method: summaryApi.current_user.method,
//       credentials: "include"
//     });
//     const dataApi = await dataResponse.json();
//     if (dataApi.success) {
//       dispatch(setUserDetails(dataApi.data));
//     }
//   };

//   const fetchUserAddToCartCount = async () => {
//     const dataResponse = await fetch(summaryApi.addToCartProductCount.url, {
//       method: summaryApi.addToCartProductCount.method,
//       credentials: "include"
//     });

//     const dataApi = await dataResponse.json();
//     setCartProductCount(dataApi?.data?.totalItems);
//   };

//   useEffect(() => {
//     fetchUserdetails();
//     fetchUserAddToCartCount();
//   }, []);

//   return (
//     <>
//       <Context.Provider value={{
//         fetchUserdetails,
//         cartProductCount,
//         fetchUserAddToCartCount,
//       }}>
//         <ToastContainer position='top-center' />
//         <Header />
//         <main className='min-h-[calc(100vh-100px)] pt-16'>
//           <Outlet />
//         </main>
//         <Footer />
//       </Context.Provider>
//     </>
//   );
// }

// export default App;





// import './App.css';
// import { Outlet } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import { ToastContainer} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useEffect, useState } from 'react';
// import summaryApi from './common';
// import Context from './context';
// import { useDispatch } from 'react-redux';
// import { setUserDetails } from './store/userSlice';

// function App() {
//   const dispatch = useDispatch();
//   const [cartProductCount, setCartProductCount] = useState(0);
//   const [localMarket, setLocalMarket] = useState(''); // Add localMarket state

//   // Fetch the user's local market from local storage
//   useEffect(() => {
//     const savedMarket = localStorage.getItem('localMarket');
//     if (savedMarket) {
//       setLocalMarket(savedMarket);
//     }
//   }, []);

//   const fetchUserdetails = async () => {
//     const dataResponse = await fetch(summaryApi.current_user.url, {
//       method: summaryApi.current_user.method,
//       credentials: "include"
//     });
//     const dataApi = await dataResponse.json();
//     if (dataApi.success) {
//       dispatch(setUserDetails(dataApi.data));
//     }
//   };

//   const fetchUserAddToCartCount = async () => {
//     const dataResponse = await fetch(summaryApi.addToCartProductCount.url, {
//       method: summaryApi.addToCartProductCount.method,
//       credentials: "include"
//     });

//     const dataApi = await dataResponse.json();
//     setCartProductCount(dataApi?.data?.totalItems);
//   };

//   useEffect(() => {
//     fetchUserdetails();
//     fetchUserAddToCartCount();
//   }, []);

//   return (
//     <>
//       <Context.Provider value={{
//         fetchUserdetails,
//         cartProductCount,
//         fetchUserAddToCartCount,
//         localMarket, // Provide localMarket
//         setLocalMarket // Provide setter for localMarket
//       }}>
//         <ToastContainer position='top-center' />
//         <Header />
//         <main className='min-h-[calc(100vh-100px)] pt-16'>
//           <Outlet />
//         </main>
//         <Footer />
//       </Context.Provider>
//     </>
//   );
// }

// export default App;


import React, { useState } from 'react'
import summaryApi from '../common'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import loginIcon from '../assest/signin.gif';

function ForgotPassword() {
  const [data, setData] = useState({email : ""})
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePassword = async(e) => {
    e.preventDefault();
  try{
      const response = await fetch(summaryApi.changePassword.url, {
        method : summaryApi.changePassword.method,
        credentials: 'include',
        headers : { 'Content-Type': 'application/json' },
        body : JSON.stringify(data)
      })
  
      const responseData = await response.json();
  
      if(!responseData){
        toast.error(responseData.message)
      }

      if(responseData.success){
        localStorage.setItem("verificationToken", responseData.token);
        toast.success(response.message || "Otp sent to your email")
        navigate('/change-password')
      }
    
  } catch(error){console.error("Google Sign-In error:", error);}

}
  
  return (
    <section id='change-password' className='mx-auto mt-14 container customMin-300:max-w-full p-4'>
      <div className='p-5 w-full bg-white max-w-sm mx-auto customMax-800:mt-5'>
              <div className='w-20 mx-auto h-20'>
                <img src={loginIcon} alt='loginIcon'/>
              </div>
        <form className='pt-6 flex mx-auto flex-col gap-2 bg-white'  onSubmit={handleChangePassword}>
          <div>
            <label>Email:</label>
            <div className=' p-2 border border-gray-300 rounded'>
              <input 
                type='email' 
                placeholder='Enter email' 
                className=' w-full h-full outline-none bg-white'
                name='email'
                value={data.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='bg-orange-500 hover:bg-orange-700 text-white cursor-pointer mt-7 px-20 py-2 mx-auto rounded'>
                  <button>SUBMIT</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ForgotPassword
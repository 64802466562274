import React from 'react'
import { Link } from 'react-router-dom'

const ErrorFallBack = () => {
  return (
    <div className='error-container'>
      <div className=''>
        <h1 className=''>Sorry something went wrong, try again or 
          click back to <Link to={'/'}>Home</Link>
        </h1>
      </div>

    </div>
  )
}

export default ErrorFallBack
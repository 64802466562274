import React from "react";

function PolicyPage() {
  return (
    <div className="bg-slate-50 min-h-screen">
      <div className="max-w-4xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-extrabold text-center text-gray-800 mb-6">
          Privacy Policy
        </h1>
        <p className="text-gray-600 leading-relaxed mb-8">
          Welcome to our platform! Please read our privacy policy carefully. We
          store a token in your browser's localStorage to authenticate your
          login session and remember your marketing preferences.
        </p>

        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              What We Collect
            </h2>
            <ul className="list-disc list-inside text-gray-600 leading-relaxed">
              <li>Authentication token for secure login.</li>
              <li>Marketing preferences for personalized offers.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              How We Protect Your Data
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              We implement secure HTTPS connections, restricted data access,
              and regular audits to ensure your data's safety. However, in
              compliance with GDPR, a customer can request that their data be
              erased from our database.
            </p>
            <p className="text-gray-600 leading-relaxed">
              We value your patronage and want to assure you that your security
              and satisfaction are our priority. For more information about our
              terms of service, click here{" "}
              <a
                href="/terms-of-service"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                Terms of Service
              </a>
              .
            </p>
          </div>

          <div>
            <p className="text-gray-600 leading-relaxed">
              For further assistance or inquiries, feel free to contact us at{" "}
              <a
                href="mailto:inquiry@homemarket2u.com"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                inquiry@homemarket2u.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyPage;

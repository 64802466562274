
import Header from '../components/Header';  // Ensure Header is imported
import Footer from '../components/Footer';  // Ensure Footer is imported
import { MarketProvider } from '../context/MarketContext'; // Ensure MarketProvider is imported


import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    this.setState({ error, errorInfo });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <MarketProvider>  {/* Wrap the error view in the MarketProvider */}
        <Header /> 
          <div className="error-container">
            <h2>Something went wrong.</h2>
            <p>{this.state.error?.toString()}</p>
            <button className="go-back-home" onClick={() => window.location.href = '/'}>Go to Home</button>
          </div>
          <Footer />  {/* Include the Footer */}
          </MarketProvider>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

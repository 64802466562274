import {  createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import SignUp from '../pages/SignUp'
import AdminPanel from '../pages/AdminPanel'
import AllUsers from '../pages/AllUsers'
import AllProducts from '../pages/AllProducts'
import CategoryProduct from '../pages/CategoryProduct'
import ProductDetail from '../pages/ProductDetail'
import Cart from '../pages/Cart'
import SearchProduct from '../pages/SearchProduct'
import ErrorBoundary from '../helpers/ErrorBoundary'
import ErrorFallBack from '../pages/ErrorsFallBack'
import OtpVerification from '../pages/OtpVerification'
import PendingApprovalProduct from '../pages/PendingApprovalProduct'
import RejectedApprovalProduct from '../pages/RejectedProduct'
import VerifyOTPChangePassword from '../pages/VerifyOTPChangePassword'
import PolicyPage from '../pages/PolicyPage'
import TermsOfService from '../pages/TermsOfService';
import ImageLogo from '../pages/ImageLogo'



const router = createBrowserRouter([
   {
    path : "/",
    element : <App/>,
    errorElement: <ErrorBoundary />, // Set the ErrorBoundary for any error in children routes
    children: [
        {
            path : "",
            element : <Home/>
        },
        
        { 
            path: "/images/logo",
            element : <ImageLogo/>
        },

        {
            path : "login",
            element : <Login/>
        },


        {
            path : "forgot-password",
            element : <ForgotPassword/>
        },

        {
            path : "change-password",
            element : <VerifyOTPChangePassword />
        },

        {
            path : "signup",
            element : <SignUp/>
        },

        {
            path : "verify-otp",
            element : <OtpVerification/>
        },

        {
            path : "product-category",
            element : <CategoryProduct/>,
        },

        {  path : "product/:id",
            element : <ProductDetail/>,
        },

        {
            path : "cart",
            element : <Cart />
        },

        {
            path : "search",
            element : <SearchProduct />
        },

        {
            path : "error-fallback",
            element : <ErrorFallBack/>
        },

        {
            path : "privacy-policy",
            element : <PolicyPage/>
        },

        {
            path : "terms-of-service",
            element : <TermsOfService />
        },

        {
            path : "admin-panel",
            element : <AdminPanel/>,
            children : [
                {
                    path : "all-users",
                    element : <AllUsers/>
                },
                {
                    path : "all-products",
                    element : <AllProducts/>
                },
                {
                    path : "pending-approval",
                    element : <PendingApprovalProduct/>
                },
                {
                    path : "rejected-approval",
                    element : <RejectedApprovalProduct/>
                },
            ]
        },
        
    ]

   }
])

export default router
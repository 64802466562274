import React, { useEffect, useState } from "react";

const CookieConsentBanner = ({ setBlockPage }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
      setBlockPage(true); // Block page interactions
    }
  }, [setBlockPage]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false);
    setBlockPage(false); // Allow interactions
  };

  if (!showBanner) return null;

  return (
    <div
      className="fixed inset-0 bg-white bg-opacity-95 z-50 flex items-center justify-center"
      style={{
        textAlign: "center",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#333",
        lineHeight: "1.5",
      }}
    >
      <div className="cookie-consent-content">
        <p>
          We use cookies to improve your experience on our platform. By accepting, you agree
          to our use of cookies as per our{" "}
          <a href="/privacy-policy" className="text-orange-600 underline">
            Privacy Policy
          </a>.
          <a href="/terms-of-service" className="text-orange-600 underline ml-5">
            Terms of service
          </a>.
        </p>
        <button
          onClick={handleAccept}
          className="mt-4 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;


// import React, { useContext, useState } from 'react';
// import loginIcon from '../assest/signin.gif';
// import { MdOutlineRemoveRedEye } from "react-icons/md";
// import { FaEyeSlash } from "react-icons/fa";
// import { Link, useNavigate } from 'react-router-dom';
// import { toast } from "react-toastify";
// import summaryApi from '../common';
// import Context from '../context';
// import { GoogleLogin } from '@react-oauth/google';

// function Login() {
//     const [showPassword, setShowPassword] = useState(false);
//     const [data, setData] = useState({ email: "", password: "" });
//     const [loading, setLoading] = useState(false);
//     const [isNewUser, setIsNewUser] = useState(false);
//     const [selectedRole, setSelectedRole] = useState(""); // Role selection state
//     const [googleToken, setGoogleToken] = useState(null); // Store Google token

//     const navigate = useNavigate();
//     const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setData((prev) => ({ ...prev, [name]: value }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
        
//         try {
//             setLoading(true);
//             const dataResponse = await fetch(summaryApi.signIn.url, {
//                 method: summaryApi.signIn.method,
//                 credentials: 'include',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify(data),
//             });
    
//             if (!dataResponse.ok) {
//                 if (dataResponse.status >= 500) {
//                     navigate('/error-fallback');
//                     return;
//                 } else {
//                     const errorData = await dataResponse.json();
//                     toast.error(errorData.message);
//                     return;
//                 }
//             }
    
//             const dataApi = await dataResponse.json();
    
//             if (dataApi.success) {
//                 toast.success(dataApi.message);
//                 navigate('/');
//                 await fetchUserdetails();
//                 await fetchUserAddToCartCount();
//             } else {
//                 toast.error(dataApi.message);
//             }
//         } catch (error) {
//             console.error("Error logging in:", error);
//             navigate('/error-fallback');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleLoginSuccess = (response) => {
//         if (!response || !response.credential) {
//           console.error("Google Sign-In error: No credential received");
//           return;
//         }
//         const googleToken = response.credential;
//         setGoogleToken(googleToken);  // Store token for later use
//         loginWithGoogle(googleToken);
//     };

//     const loginWithGoogle = async (googleToken) => {
//         if (!googleToken) {
//             console.error("Google token is missing.");
//             return;
//         }
//         try {
//             const response = await fetch('http://localhost:8080/api/auth/google', {
//                 method: 'POST',
//                 credentials: 'include',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ token: googleToken }),
//             });
            
//             const data = await response.json();

//             if (response.ok) {
//                 setGoogleToken(googleToken); // Store the token for role submission
//                 if (data.isNewUser) {
//                     setIsNewUser(true); // Show role selection dialog
//                 } else {
//                     toast.success("Logged in successfully!");
//                     navigate('/');
//                     await fetchUserdetails();
//                     await fetchUserAddToCartCount();
//                 }
//             } else {
//                 const data = await response.json().catch(() => null);
//                 console.error("Login failed:", data ? data.message : "No error message provided");
//                 toast.error(data ? data.message : "An unexpected error occurred");
//             }
//         } catch (error) {
//             console.error("Error during login:", error);
//         }
//     };

    

//     return (
//         <section id='login'>
//             <div className='mx-auto mt-5 container p-4'>
//                 <div className='p-5 w-full bg-white max-w-sm mx-auto customMax-800:mt-5'>
//                     <div className='w-20 mx-auto h-20'>
//                         < src={loginIcon} alt='loginIcon'/>
//                     </div>

//                     <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
//                         <div>
//                             <label>Email:</label>
//                             <div className='bg-slate-100 p-2'>
//                                 <input 
//                                     type='email' 
//                                     placeholder='enter email' 
//                                     className='w-full h-full bg-transparent outline-none'
//                                     name='email'
//                                     value={data.email}
//                                     onChange={handleChange}
//                                 />
//                             </div>
//                         </div>

//                         <div>
//                             <label>Password:</label>
//                             <div className='bg-slate-100 p-2 flex'>
//                                 <input 
//                                     type={showPassword ? "text" : "password"} 
//                                     placeholder='enter password' 
//                                     name='password'
//                                     value={data.password}
//                                     onChange={handleChange}
//                                     className='w-full h-full bg-transparent outline-none'
//                                 />
//                                 <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
//                                     {showPassword ? <FaEyeSlash /> : <MdOutlineRemoveRedEye />}
//                                 </div>
//                             </div>  
//                         </div>  
                        
//                         <div className='hover:underline hover:text-orange-700 cursor-pointer ml-auto'>
//                             <Link to={'/forgot-password'}>forgotten password?</Link>
//                         </div>

//                         <div className='bg-orange-500 hover:bg-orange-700 text-white cursor-pointer mt-7 px-20 py-2 mx-auto rounded'>
//                             <button>Login</button>
//                         </div>

//                         {/* Google Sign-in Button */}
//                         <GoogleLogin
//                             onSuccess={handleLoginSuccess}
//                             onError={() => console.log('Login Failed')}
//                         />

//                     </form>
//                     <p className='my-4 hover:underline hover:text-orange-700 cursor-pointer'> 
//                         <Link to={"/signup"}>Don't have an account? Sign up</Link>
//                     </p>

//                 </div>
//             </div>
//         </section>
//     );
// }

// export default Login;




import React, { useContext, useState } from 'react';
import loginIcon from '../assest/signin.gif';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import summaryApi from '../common';
import Context from '../context';
import { GoogleLogin } from '@react-oauth/google';
import ChooseUserRole from '../components/ChooseUserRole';
import PrivacyPolicyDialog from '../components/PrivacyPolicyDialog';
import LoadingIcons from 'react-loading-icons'

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({ email: '', password: '' });
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [newUserId, setNewUserId] = useState(null); // Track new Google user ID
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const dataResponse = await fetch(summaryApi.signIn.url, {
                method: summaryApi.signIn.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

            if (!dataResponse.ok) {
              console.log('loading state:', loading)
              if (dataResponse.status >= 500) {
                  navigate("/error-fallback");
              } else {
                  const errorData = await dataResponse.json();
                  toast.error(errorData.message || "Error during registration");
                  setLoading(false);
              }
              return;
            }

            const dataApi = await dataResponse.json();
            if (dataApi.redirectToVerify){
                localStorage.setItem("verificationToken", dataApi.token);
                toast.success(dataApi.message)
                navigate("/verify-otp");
            } else if (dataApi.success) {
                toast.success(dataApi.message);
                await fetchUserdetails();
                await fetchUserAddToCartCount();
                navigate('/');
            }
        } catch (error) {
            console.error("Error logging in:", error);
            navigate('/error-fallback');
            setLoading(false)
        } 
    };

    // Google login handler
    const handleLoginSuccess = (response) => {
        const googleToken = response.credential;
        loginWithGoogle(googleToken);
    };

    const loginWithGoogle = async (googleToken) => {
        try {
            const response = await fetch(summaryApi.googleLogin.url, {
                method: summaryApi.googleLogin.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: googleToken }),
            });
    
           
            const data = await response.json(); // Parse JSON once
    
            // Check for 400 status to display error message
            if (response.status === 400) {
                toast.error(data.message || "An error occurred during Google Sign-In.");
                return; // Stop execution here if there's an error
            }
    
            // Handle success case if the response is OK
            if (response.ok && data.success) {
                setNewUserId(data.user._id);
                if (!data.user.role) {
                    setShowRoleSelection(true); // Show role selection for new users
                    
                } else {
                    navigate('/');
                    await fetchUserdetails();
                    await fetchUserAddToCartCount();
                }
            } else {
                // Handle unexpected cases if status isn't 400 but also not OK
                console.log("Unexpected response status or success flag:", response.status, data.success);
            }
    
        } catch (error) {
            console.error("Google Sign-In error:", error);
        }
    };
    

    // Handle role selected in ChooseUserRole
    const handleRoleSelected = async () => {
        setShowRoleSelection(false);
        await fetchUserdetails();
        await fetchUserAddToCartCount();
        navigate('/');
    };

    const handleOpenPolicy = () => setShowPrivacyPolicy(true);
    const handleClosePolicy = () => setShowPrivacyPolicy(false);

    return (
        <section id='login'>
          <div className='mx-auto mt-5 container customMin-300:max-w-full p-4'>
            <div 
              className='p-5 w-full bg-white max-w-sm mx-auto customMax-800:mt-5'
              style={{
                opacity: showRoleSelection ? 0.1 : 1, 
                transition: 'opacity 0.3s ease',
                pointerEvents: showRoleSelection ? 'none' : 'auto', // Disable interaction with the form when role selection is shown
              }}
            >
              <div className='w-20 mx-auto h-20'>
                <img src={loginIcon} alt='loginIcon'/>
              </div>
      
              <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
                <div>
                  <label>Email:</label>
                  <div className='bg-slate-100 p-2'>
                    <input 
                      type='email' 
                      placeholder='enter email' 
                      className='w-full h-full bg-transparent outline-none'
                      name='email'
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
      
                <div>
                  <label>Password:</label>
                  <div className='bg-slate-100 p-2 flex'>
                    <input 
                      type={showPassword ? "text" : "password"} 
                      placeholder='enter password' 
                      name='password'
                      value={data.password}
                      onChange={handleChange}
                      className='w-full h-full bg-transparent outline-none'
                    />
                    <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
                      {showPassword ? <FaEyeSlash /> : <MdOutlineRemoveRedEye />}
                    </div>
                  </div>  
                </div>  
      
                <div className='hover:underline hover:text-orange-700 cursor-pointer ml-auto'>
                  <Link to={'/forgot-password'}>forgotten password?</Link>
                </div>
      
                <div className='bg-orange-500 hover:bg-orange-700 text-white cursor-pointer mt-7 px-20 py-2 mx-auto rounded'>
                  <button type="submit" disabled={loading}>
                        {
                            loading ? (<LoadingIcons.Bars className="text-orange-600 h-[1.33em]"/>) : ('Login')
                        }
                  </button>
                </div>
      
                {/* Google Sign-in Button */}
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={() => console.log('Login Failed')}
                />
              </form>
              <p className='my-4 hover:underline hover:text-orange-700 cursor-pointer'> 
                <Link to={"/signup"}>Don't have an account? Sign up</Link>
              </p>

              <button onClick={handleOpenPolicy} className='hover:underline hover:text-orange-600'>Privacy Policy</button>

              {/* Privacy Policy Dialog */}
              <PrivacyPolicyDialog open={showPrivacyPolicy} onClose={handleClosePolicy} />

            </div>
          </div>
      
          {/* Role Selection Dialog */}
          {showRoleSelection && (
            <ChooseUserRole userId={newUserId} onRoleSelected={handleRoleSelected} />
          )}
        </section>
      );
      

}

export default Login;
   // return (
    //     <section id="login">
    //         <div className="mx-auto mt-5 container p-4">
    //             <div className="p-5 w-full bg-white max-w-sm mx-auto">
    //                 <div className="w-20 mx-auto h-20">
    //                     <img src={loginIcon} alt="loginIcon" />
    //                 </div>

    //                 <form className="pt-6 flex flex-col gap-2" onSubmit={handleSubmit}>
    //                     <label>Email :</label>
    //                     <input type="email" name="email" value={data.email} onChange={handleChange} />

    //                     <label>Password :</label>
    //                     <input type={showPassword ? "text" : "password"} name="password" value={data.password} onChange={handleChange} />

    //                     <button type="submit">Login</button>

    //                     <GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.log('Login Failed')} />
    //                 </form>

    //                 <Link to="/signup">Don't have account? Sign up</Link>

    //                 {showRoleSelection && (
    //                     <ChooseUserRole userId={newUserId} onRoleSelected={handleRoleSelected} />
    //                 )}
    //             </div>
    //         </div>
    //     </section>
    // );

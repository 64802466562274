


import React, { useContext, useEffect, useRef, useState } from 'react';
import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
import displayPrice from '../helpers/FormatPrice';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/AddToCart';
import Context from '../context';

const RecommendationDisplay = ({ localMarket, category, heading  }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const scrollElement = useRef();

    const { fetchUserAddToCartCount} = useContext(Context)

    const handleAddCart = async(e, id) =>{
        await addToCart(e, id)
        fetchUserAddToCartCount()
    }

    const fetchData = async () => {
        setLoading(true);
        const categoryProduct = await fetchProductsByCategory(localMarket, category);
        setLoading(false);
        setData(categoryProduct?.data || []);
    };

    useEffect(() => {
        fetchData();
    }, [category]);

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };

    return (
        <div className='container customMin-300:max-w-full w-full max-w-full mx-auto px-4 my-6 relative'>
            <div className='customMax-800:max-w-full '  >

            <h2 className='text-2xl font-semibold py-4'>
                {heading}
            </h2>

            {/* <div classname='grid grid-cols-[repeat(auto-fit, minmax(300px,320px))] justify-between  */}
            {loading ? (
                // Skeleton Loader
                <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none'>
                    {Array(6).fill(null).map((_, index) => (
                        <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
                            <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                <div className='bg-gray-300 w-full h-full'></div>
                            </div>
                            <div className='p-4 grid gap-3'>
                                <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
                                <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
                                <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
                                <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : data.length === 0 ? (
                <div className='text-center'>
                    <p>No products found in this category.</p>
                </div>
            ) : (
                <div className='flex items-center' >
                    <button
                        className='bg-white shadow-md rounded-full p-2 absolute left-0 text-lg hidden md:block z-10'
                        onClick={scrollLeft}
                        aria-label="Scroll left"
                    >
                        <FaAngleLeft />
                    </button>

                    <button
                        className='bg-white shadow-md rounded-full p-2 absolute right-0 hidden md:block z-10'
                        onClick={scrollRight}
                        aria-label="Scroll right"
                    >
                        <FaAngleRight />
                    </button>

                    <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none ' ref={scrollElement} >
                        {data.map((product, index) => (
                           <Link to={`/product/${product?._id}`} key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                                <div className=' h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                    <img
                                        src={product.productImage[0]}
                                        alt={product?.itemName}
                                        className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
                                    />
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
                                        {product?.itemName}
                                    </h2>
                                    <p className='capitalize text-slate-500'>{product?.category}</p>
                                    <div className='text-orange-800'>
                                        <p>{displayPrice(product?.price, product?.currency)}</p>
                                    </div>
                                    <button className='bg-white  hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full border' onClick={ (e) => handleAddCart(e, product?._id)}>
                                        Add to Cart
                                    </button>
                                </div>
                            </Link>
                        ))}
                    </div>

                </div>
            )}
            </div>
        </div>
    );
};

export default RecommendationDisplay;


// import React, { useContext, useEffect, useRef, useState } from 'react';
// import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
// import displayPrice from '../helpers/FormatPrice';
// import { Link } from 'react-router-dom';
// import addToCart from '../helpers/AddToCart';
// import Context from '../context';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

// const RecommendationDisplay = ({ localMarket, category, heading, className = '' }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(1); 
//     const [hasMore, setHasMore] = useState(true); 
//     const scrollElement = useRef();

//     const { fetchUserAddToCartCount } = useContext(Context);

//     const handleAddCart = async (e, id) => {
//         await addToCart(e, id);
//         fetchUserAddToCartCount();
//     };

//     const fetchData = async () => {
//         if (loading || !hasMore) return;

//         setLoading(true);
//         const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 20); 
//         setLoading(false);
        
//         if (categoryProduct && categoryProduct.data.length > 0) {
//             setData((prevData) => [...prevData, ...categoryProduct.data]);
//             if (categoryProduct.data.length < 20) {
//                 setHasMore(false);
//             }
//         } else {
//             setHasMore(false);
//         }
//     };

//     useEffect(() => {
//         setData([]);
//         setPage(1); 
//         setHasMore(true);
//         fetchData();
//     }, [localMarket, category]);
    
//     useEffect(() => {
//         console.log("recommedFetchData:",)
//         if (page > 1) {
//             fetchData();
//         }
//     }, [page]);

//     const handleScroll = () => {
//         if (!scrollElement.current) return;
//         const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
//         const threshold = 5; 
//         const currentItemsCount = data.length;

//         if (scrollLeft + clientWidth >= scrollWidth - threshold * 300 && hasMore && currentItemsCount >= 20) {
//             setPage((prevPage) => prevPage + 1);
//         }
//     };

//     useEffect(() => {
//         if (scrollElement.current) {
//             scrollElement.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (scrollElement.current) {
//                 scrollElement.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [hasMore]);

//     const scrollRight = () => {
//         scrollElement.current.scrollLeft += 300;
//     };

//     const scrollLeft = () => {
//         scrollElement.current.scrollLeft -= 300;
//     };

//     return (
//         <div className='container mx-auto px-4 my-6 relative'>
//             <div className={`customMax-800 ${className}`}>
//                 <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

//                 {loading && data.length === 0 ? (
//                     <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none'>
//                         {Array(6).fill(null).map((_, index) => (
//                             <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
//                                 <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                     <div className='bg-gray-300 w-full h-full'></div>
//                                 </div>
//                                 <div className='p-4 grid gap-3'>
//                                     <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
//                                     <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
//                                     <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
//                                     <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : data.length === 0 ? (
//                     <div className='text-center'>
//                         <p>No products found in this category.</p>
//                     </div>
//                 ) : (
//                     <div className='relative flex items-center'>
//                         <button
//                             className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden md:block z-10'
//                             onClick={scrollLeft}
//                             aria-label="Scroll left"
//                         >
//                             <FaAngleLeft />
//                         </button>

//                         <button
//                             className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden md:block z-10'
//                             onClick={scrollRight}
//                             aria-label="Scroll right"
//                         >
//                             <FaAngleRight />
//                         </button>

//                         <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
//                             {data.map((product, index) => (
//                                 <Link to={"product/" + product?._id} key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
//                                     <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                         <img
//                                             src={product.productImage[0]}
//                                             alt={product?.itemName}
//                                             className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//                                         />
//                                     </div>
//                                     <div className='p-4 grid gap-3'>
//                                         <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
//                                             {product?.itemName}
//                                         </h2>
//                                         <p className='capitalize text-slate-500'>{product?.category}</p>
//                                         <div className='text-orange-800'>
//                                             <p>{displayPrice(product?.price, product?.currency)}</p>
//                                         </div>
//                                         <button
//                                             className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
//                                             onClick={(e) => handleAddCart(e, product?._id)}
//                                         >
//                                             Add to Cart
//                                         </button>
//                                     </div>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default RecommendationDisplay;


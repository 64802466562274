import React, { useContext, useState } from 'react';
import summaryApi from '../common';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../context';
import { FaEyeSlash } from 'react-icons/fa';
import { MdOutlineRemoveRedEye } from 'react-icons/md';


const VerifyOTPChangePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const {fetchUserdetails, fetchUserAddToCartCount} = useContext(Context)

   
    const handleOtpVerification = async (e) => {
        e.preventDefault();
    
        const token = localStorage.getItem("verificationToken");
        if (!token) {
            toast.error("Verification token not found. Please request OTP again.");
            return;
        }
    
        try {
            setLoading(true);
    
            const response = await fetch(summaryApi.verifyChangePassword.url, {
                method: summaryApi.verifyChangePassword.method,
                credentials: 'include', // Ensure credentials are included
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ otp, token, newPassword: password })
            });
    
            const result = await response.json();
            if (result.success) {
                toast.success(result.message);
                localStorage.removeItem("verificationToken");

                try{
                    if(result.newToken){
                        await fetchUserdetails();
                        await fetchUserAddToCartCount();
                    }
                }catch(error){
                    console.log('something wrong with token', error)
                    toast.error(error)
                }
              
    
                // Attempt to fetch user details after successful password reset and navigate
                try {
                    await fetchUserdetails();
                    await fetchUserAddToCartCount();
                } catch (fetchError) {
                    console.error("Error fetching user details or cart count:", fetchError);
                    toast.error(fetchError)
                }
                navigate('/');
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.error("Verification failed:", error);
            toast.error("Failed to verify OTP");
        } finally {
            setLoading(false);
        }
    };
    

    
    const handleResendOtp = async () => {
        // Retrieve the token and check if it exists
        const token = localStorage.getItem("verificationToken");
        if (!token) {
            toast.error("Verification token not found. Please try to log in or register again.");
            return;
        }
    
        try {
            setResendLoading(true);
    
            const response = await fetch(summaryApi.resend_otp.url, {
                method: summaryApi.resend_otp.method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });
    
            const result = await response.json();
            if (result.success) {
                toast.success("A new OTP has been sent to your email.");
            } else if(response.status === 401){
                toast.error('your session has expired. Please login to verify email.')
                navigate('/login')
            } else {
                toast.error(result.message || "Failed to resend OTP. Please try again.");
            }
        } catch (error) {
            console.error("Failed to resend OTP:", error);
            toast.error("Failed to resend OTP.");
        } finally {
            setResendLoading(false);
        }
    };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full -mt-32">
                <h1 className="text-2xl font-semibold text-center mb-4">Verify Your Email</h1>
                <p className="text-gray-600 text-center mb-6">Please enter the OTP sent to your email.</p>
                <form onSubmit={handleOtpVerification} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />

                    <div className=' p-2 flex outline-none rounded border border-gray-300'>
                        <input 
                        type={showPassword ? "text" : "password"} 
                        placeholder='Enter password' 
                        name='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='w-full h-full bg-transparent outline-none'
                        />
                        <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
                        {showPassword ? <FaEyeSlash /> : <MdOutlineRemoveRedEye />}
                        </div>
                    </div>
                    <button 
                        type="submit" 
                        disabled={loading} 
                        className="w-full  text-white py-2 rounded-md bg-orange-500 hover:bg-orange-700 transition-colors duration-200 disabled:bg-indigo-400">
                        {loading ? "Verifying..." : "Verify OTP"}
                    </button>
                </form>
                <div className="text-center mt-4">
                    <button 
                        onClick={handleResendOtp} 
                        disabled={resendLoading} 
                        className="text-indigo-600 hover:underline mt-2">
                        {resendLoading ? "Resending OTP..." : "Resend OTP"}
                    </button>
                </div>
            </div>
        </div>
  )
}

export default VerifyOTPChangePassword
// Helper function to format a number as currency (with commas and two decimal places)

const displayPrice = (price, currency) => {
    // Define a mapping of currency codes to locales
    const localeMap = {
        NGN: 'en-NG',
        USD: 'en-US',
        EUR: 'de-DE', // Example for Euro
        GBP: 'en-GB', // Example for British Pound
        // Add more currency mappings as needed
    };

    // Check if currency is valid; if not, log an error and use a default currency
    if (!currency || typeof currency !== 'string') {
        console.error("Invalid currency provided:", currency);
        currency = 'USD'; // Set a default currency
    }

    // Determine the locale based on currency
    const locale = localeMap[currency] || 'en-US'; // Fallback to 'en-US'

    // Ensure price is a valid number
    if (isNaN(price)) {
        console.error("Invalid price provided:", price);
        return price; // or return a default value
    }

    // Create the formatter with the specified locale and currency
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    });

    // Return the formatted currency string
    return formatter.format(price);
};

export default displayPrice;




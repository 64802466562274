import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gray-50 text-gray-800 min-h-screen py-10 px-4">
      <div className="max-w-4xl mx-auto bg-white p-6 shadow-md rounded-lg">
        <h1 className="text-2xl font-bold text-center mb-4">Terms of Service</h1>
        <p className="text-sm text-gray-500 text-center mb-6">
          Last Updated: <span className="font-semibold">[23 Nov. 2024]</span>
        </p>

        <div className="space-y-6">
          <section>
            <h2 className="text-lg font-semibold mb-2">1. Acceptance of Terms</h2>
            <p>
              By using our platform, you affirm that you are at least 18 years old or the age of majority in your
              jurisdiction and have the legal capacity to enter into these Terms. If you do not agree to these Terms, you
              must not use our platform.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">2. Scope of Services</h2>
            <p>
              Our platform is designed to serve the Nigerian and Indian diaspora in the United Kingdom, United States, and
              other locations. We provide an online marketplace for goods and services, secure payment options, and delivery
              or shipping services. We reserve the right to modify, suspend, or discontinue any part of the platform at our
              discretion.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">3. User Accounts</h2>
            <h3 className="font-medium">3.1 Registration</h3>
            <p>
              To use certain features of our platform, you must create an account. You agree to provide accurate and
              up-to-date information and to keep your account credentials secure.
            </p>
            <h3 className="font-medium mt-2">3.2 Account Responsibility</h3>
            <p>
              You are responsible for all activities under your account. Notify us immediately if you suspect unauthorized
              access or a security breach.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">4. Products and Transactions</h2>
            <h3 className="font-medium">4.1 Product Listings</h3>
            <p>
              We strive to provide accurate product descriptions, images, and pricing. However, we do not guarantee the
              accuracy, completeness, or reliability of such information.
            </p>
            <h3 className="font-medium mt-2">4.2 Payments</h3>
            <p>
              All payments are processed securely via third-party payment providers. By making a purchase, you agree to their
              terms and conditions.
            </p>
            <h3 className="font-medium mt-2">4.3 Refunds and Returns</h3>
            <p>
              Refund and return policies vary depending on the product and seller. Please review the specific policy on the
              product page before purchasing.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">5. User Conduct</h2>
            <p>
              By using our platform, you agree to comply with all applicable laws and regulations, not engage in fraudulent,
              abusive, or harmful activities, and respect other users, sellers, and our staff. We reserve the right to
              suspend or terminate accounts that violate these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">6. Intellectual Property</h2>
            <p>
              All content on our platform, including logos, trademarks, and design elements, is owned by or licensed to us.
              You may not use, reproduce, or distribute our intellectual property without prior written consent.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">7. Privacy and Data Protection</h2>
            <p>
              We value your privacy. By using our platform, you consent to the collection, use, and storage of your personal
              information as outlined in our <a href="/privacy-policy" className="text-blue-600 underline">Privacy Policy</a>.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">8. Governing Law</h2>
            <p>
              These Terms are governed by the laws of England and Wales for UK users and the laws of the State of Delaware
              for US users. Disputes are subject to the exclusive jurisdiction of the relevant courts.
            </p>
          </section>
        </div>

        <div className="mt-6 text-center">
          <p>
            For questions, contact us at: <a href="mailto:inquiry@homemarket2u.com" className="text-blue-600 underline">inquiry@homemarket2u.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;

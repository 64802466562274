

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import summaryApi from '../common'
import { FaStar } from "react-icons/fa";
import { FaStarHalf } from "react-icons/fa6";
import displayPrice from '../helpers/FormatPrice';
import RecommendationDisplay from '../components/RecommendationDisplay';
import addToCart from '../helpers/AddToCart';
import Context from '../context';
import { MarketContext } from '../context/MarketContext'; // Import the context


const ProductDetail = () => {
    const { localMarket} = useContext(MarketContext); // Get market from context
    const [data, setData] = useState({
        itemName: "",
        price: "",
        category: "",
        productImage: [],
        itemDescription: "",
        DeliveryTo: [],
        currency:  'USD' ,
    })

    const params = useParams()
    const [loading, setLoading] = useState(false)
    const productImageLoading = new Array(4).fill(null)
    const [activeImage, setActiveImage] = useState("")

    const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
        x : 0,
        y : 0
    })
    const [zoomImage, setZoomImage] = useState(false)
    const navigate = useNavigate();

    const { fetchUserAddToCartCount} = useContext(Context)

    const handleAddCart = async(e, id) =>{
        await addToCart(e, id)
        fetchUserAddToCartCount()
    }

    const fetchProductDetails = async () => {

        try{
            setLoading(true)
            const response = await fetch(summaryApi.productDetails.url, {
                method: summaryApi.productDetails.method,
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    productId: params?.id
                })
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            
          
            const dataResponse = await response.json()
    
           
    
            if (dataResponse?.data) {
                setData(dataResponse?.data)
                setActiveImage(dataResponse?.data?.productImage[0])
            } else {
                console.log("No data found")
            }

        } catch (error) {
            console.error("Error fetching product detail:", error);
            navigate('/error-fallback');
        } finally {
            setLoading(false);
        }
        
    }


    useEffect(() => {
        fetchProductDetails()

        window.scrollTo(0, 0);
    }, [params])

    const handleMouseEnterProduct = (imageURL) =>{
        setActiveImage (imageURL)
    }

    const handleZoomImage = (e) =>{
        setZoomImage(true)
        const { left, top, width, height } = e.target.getBoundingClientRect()
        console.log("coordinate:", left, top, width, height)

        const x = (e.clientX - left) / width
        const y = (e.clientY - top) / height

        setZoomImageCoordinate({
            x,
            y
        })
    }
    const handleLeaveImageZoom = () =>{
        setZoomImage(false)
    }

   
    return (
        <div  className='container customMin-300:max-w-full w-full mx-auto p-4 customMax-800:mt-9'>
            <div className='min-h-[200px] flex custom-750-1023:flex-row flex-col lg:flex-row gap-4 w-full max-w-full '>
                {/* product image */}
                <div className='h-96 customMax-525:h-full flex flex-col lg:flex-row-reverse gap-4  max-w-full customMax-749:items-center p-2'>
                    <div className='h-96 customMax-525:max-h-full'>
                        <div className='h-[300px] w-[300px] customMax-525:w-full customMax-525:h-full lg:h-96 lg:w-96  relative'>
                            <img src={activeImage} alt='product image' className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom}/>

                            {/* product image zoom */}
                            {
                                zoomImage && (
                                    <div className='hidden lg:block absolute h-96 w-96 bg-slate-200 p-1 -right-[410px] top-0'>
                                        <div className='w-full h-full bg-cover mix-blend-multiply'
                                        style={{
                                            backgroundImage: `url(${activeImage})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition : `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`,
                                            backgroundSize: "150%", // This controls the zoom level
        
                                        }}>
        
                                        </div>
    
                                    </div>
                                )
                            }
                         
                        </div>
                    </div>
                    <div className='h-full'>
                        {loading ? (
                            <div className='flex  gap-2 lg:flex-col overflow-scroll scrollbar-none h-full max-w-full'>
                                {
                                    productImageLoading.map((el, index)=>{
                                    return (
                                        <div className='h-20 w-20 rounded bg-slate-200 animate-pulse' key={index}>
                                            
                                        </div>
                                    )
                                    })
                                      
                                }
                            </div>
                            ) : (
                                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full max-w-[310px]'>
                                    {
                                       data.productImage.map((imgURL, index)=>{
                                        return (
                                            <div className='h-20 w-20 rounded bg-slate-200' key={index}>
                                                <img src={imgURL} alt='product link' className='w-full h-full object-scale-down mix-blend-multiply cursor-pointer' onClick={() => handleMouseEnterProduct(imgURL)} onMouseEnter={() => handleMouseEnterProduct(imgURL)}/>
                                            </div>
                                        )
                                        })
                                        
                                    }
                                </div>
                            )}
                        </div>
                </div>

                {/* product details */}
                {
                    loading ? (
                        <div className='flex flex-col gap-4 '>
                            <h2 className='bg-slate-200 text-2xl  md:text-4xl animate-pulse h-6'></h2>
                            <p className='capitalize bg-slate-200 animate-pulse min-w-[150px]'></p>

                            <div className='bg-slate-200 flex animate-pulse h-6 rounded items-center gap-1'>
                                
                            </div>

                            <div className='flex items-center w-[110px] h-5 bg-slate-200 animate-pulse my-1'>
                                    <p className='bg-slate-200 animate-pulse h-full rounded '></p>
                            </div>

                            <div className='flex items-center gap-3 my-2 '>
                                <button className=' bg-slate-200 animate-pulse h-6 w-[70px] rounded'></button>
                                <button className=' px-3 py-1 font-medium h-6 w-[100px] bg-slate-200 animate-pulse'></button>
                            </div>

                            <div>
                                <p className='bg-slate-200 w-[300px] h-24 animate-pulse font-medium my-1'> </p>
                                <p className='bg-slate-200 '></p>
                            </div>
                        </div>
                    ) : (
                        <div className=' customMax-525:text-xl flex flex-col gap-1 customMax-749:p-3'>
                            <h2 className='text-4xl md:text-4xl font-medium'>{data?.itemName}</h2>
                            <p className='capitalize text-slate-400'>{data?.category}</p>

                            <div className='text-orange-600 flex items-center gap-1'>
                                <FaStar/>
                                <FaStar/>
                                <FaStar/>
                                <FaStar/>
                                <FaStarHalf />
                            </div>

                            <div className='flex items-center text-2xl md:text-3xl my-1 font-medium text-orange-900'>
                                    <p>{displayPrice( data?.price, data?.currency )}</p>
                            </div>

                            <div className='flex items-center gap-3 my-2 '>
                                <button className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] text-orange-700 hover:text-white hover:bg-orange-600 font-medium'>Buy</button>
                                <button className='border-2 border-orange-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-orange-600 hover:bg-white hover:text-orange-700'onClick={((e)=> handleAddCart(e, data?._id))}>Add to Cart</button>
                            </div>

                            <div>
                                <p className='text-slate-600 font-medium my-1'>Description: </p>
                                <p>{data?.itemDescription}</p>
                            </div>
                        </div>

                    )
                }
                
               

            </div>

           

            <RecommendationDisplay localMarket={localMarket} category={data?.category} heading={"Recommended Products"} key={`recommend-${localMarket}1`}/>
          
        </div>

        
    )
}

export default ProductDetail

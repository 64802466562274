
// import React, { useContext, useEffect, useRef, useState } from 'react';
// import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
// import displayPrice from '../helpers/FormatPrice';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
// import { Link } from 'react-router-dom';
// import addToCart from '../helpers/AddToCart';
// import Context from '../context';

// const HorizontalCardProduct = ({ localMarket, category, heading }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const loadingList = new Array(13).fill(null);
    
//     // Pagination and scroll tracking
//     const [page, setPage] = useState(1);
//     const [hasMore, setHasMore] = useState(true);
//     const scrollElement = useRef();
    
//     const { fetchUserAddToCartCount } = useContext(Context);

//     const handleAddCart = async (e, id) => {
//         await addToCart(e, id);
//         fetchUserAddToCartCount();
//     };

//     // Fetch data with pagination
//     const fetchData = async () => {
//         if (loading || !hasMore) return; // Prevent loading if already in progress or no more products
        
//         setLoading(true);
//         const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 4); // Fetch 20 items per page
//         setLoading(false);
        
//         if (categoryProduct && categoryProduct.data.length > 0) {
//             setData((prevData) => [...prevData, ...categoryProduct.data]); // Append new products to existing data
//             if (categoryProduct.data.length < 4) {
//                 setHasMore(false); // No more products if less than 20 were fetched
//             }
//         } else {
//             setHasMore(false); // No more products available
//         }
//     };

//     useEffect(() => {
//         // Reset the data, page, and hasMore state when localMarket changes
//         setData([]);
//         setPage(1); // Reset to page 1
//         setHasMore(true); // Reset pagination

//         fetchData(); // Fetch data for the first page and new market
//     }, [localMarket]);
    
//    // Only listen for page changes after market is set
//     useEffect(() => {
//     if (page > 1) {
//       fetchData();
//     }
//     }, [page]);// Fetch data when page or localMarket changes

//     // Handle scroll event to load next page when scrolled to the right
//     const handleScroll = () => {
//         const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
//         if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore) {
//             setPage((prevPage) => prevPage + 1); // Load next page
//         }
//     };

//     useEffect(() => {
//         if (scrollElement.current) {
//             scrollElement.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (scrollElement.current) {
//                 scrollElement.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [hasMore]); // Reattach scroll event when hasMore changes

//     const scrollRight = () => {
//         scrollElement.current.scrollLeft += 300;
//     };

//     const scrollLeft = () => {
//         scrollElement.current.scrollLeft -= 300;
//     };





import React, { useContext, useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
import displayPrice from '../helpers/FormatPrice';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/AddToCart';
import Context from '../context';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const HorizontalCardProduct = forwardRef(({ localMarket, category, heading }, ref) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const scrollElement = useRef();
    const loadingList = new Array(13).fill(null);

    const { fetchUserAddToCartCount } = useContext(Context);

    const handleAddCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCartCount();
    };

    // Scroll to the left
    const scrollLeft = () => {
        if (scrollElement.current) {
            scrollElement.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    // Scroll to the right
    const scrollRight = () => {
        if (scrollElement.current) {
            scrollElement.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };


    const fetchData = useCallback(async () => {
        if (loading || !hasMore) return;
    
        setLoading(true);
        try {
            const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 4);
            
            if (categoryProduct && Array.isArray(categoryProduct?.data) && categoryProduct?.data.length > 0) {
                setData((prevData) => [...prevData, ...categoryProduct.data]);
                if (categoryProduct.data.length < 4) setHasMore(false);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [page, localMarket, category, loading, hasMore]);
    
    useEffect(() => {
        if (page === 1) {
            setData([]);
        }
        fetchData();
    }, [page, localMarket]);
    

    useImperativeHandle(ref, () => ({
        loadMore: () => setPage((prevPage) => prevPage + 1),
    }));

    useEffect(() => {
        setData([]);
        setPage(1);
        setHasMore(true);
        fetchData();
    }, [localMarket]);

    useEffect(() => {
        if (page > 1) {
            fetchData();
        }
    }, [page]);

    
    const handleScroll = useCallback(() => {
        if (!scrollElement.current) return;
        
        const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
        
        if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore && !loading) {
            console.log('Triggering loadMore...');
            setPage((prevPage) => prevPage + 1);
        }
    }, [hasMore, loading]);
    
    
    useEffect(() => {
        const currentElement = scrollElement.current;
        if (currentElement) {
            currentElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentElement) {
                currentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);



    return (
        <div className='container w-full customMin-300:max-w-full mx-auto px-4 my-6 relative'>
            <h2 className='text-2xl font-semibold py-4'>
                {heading}
            </h2>

            <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
                <button className='bg-white shadow-md rounded-full p-1 absolute left-0 text-2xl h-12 hidden md:block ' onClick={scrollLeft}>
                    <FaAngleLeft />
                </button>
                <button className='bg-white shadow-md rounded-full p-1 absolute right-0 hidden md:block transition-all text-2xl h-12' onClick={scrollRight}>
                    <FaAngleRight />
                </button>

                {loading && data.length === 0 ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='flex w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-40 bg-white rounded-sm shadow'>
                            <div className='h-full p-4 min-w-[120px] md-max-w-[145px] bg-slate-200 animate-pulse'></div>
                            <div className='p-4 grid gap-1 w-full'>
                                <h2 className='capitalize font-medium text-bold md:text-lg text-ellipsis line-clamp-1 text-black p-1 animate-pulse bg-slate-200'></h2>
                                <p className='capitalize text-slate-500 p-1 bg-slate-200'></p>
                                <div className='flex p-4 w-full gap-3 animate-pulse'>
                                    <p className='fon-medium bg-slate-200 p-1 w-full'></p>
                                    <p className='line-through bg-slate-200 p-1 w-full'></p>
                                </div>
                                <button className='text-black text-sm px-2 customMax-800:px-0.5 py-1 rounded-full animate-pulse w-full bg-slate-200'></button>
                            </div>
                        </div>
                    ))
                ) : (
                    data.map((product, index) => (
                        <Link to={"product/" + product?._id} key={product.id || index} className='flex w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-48 bg-white rounded-sm shadow'>
                            <div className='h-full p-4 min-w-[120px] md-max-w-[145px]'>
                                <img src={product.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt={product?.itemName} />
                            </div>
                            <div className='p-4 grid'>
                                <h2 className='capitalize font-medium text-bold md:text-lg text-ellipsis line-clamp-2 text-black'>{product?.itemName}</h2>
                                <p className='capitalize text-slate-500'>{product?.category}</p>
                                <div className='text-orange-800'>
                                    <p>{displayPrice(product?.price, product?.currency)}</p>
                                </div>
                                <button className='bg-orange-600 hover:bg-orange-700 text-black text-sm px-2 customMax-800:px-0.5 py-1 rounded-full' onClick={(e) => handleAddCart(e, product?._id)}>
                                    Add to Cart
                                </button>
                            </div>
                        </Link>
                    ))
                )}
                {!loading && data.length === 0 && <p>No products found.</p>}
            </div>

            {loading && data.length > 0 && <div>Loading more products...</div>}
            {!hasMore && <div>No more products to load.</div>}
        </div>
    );

});

export default HorizontalCardProduct;



// const HorizontalCardProduct = ({localMarket, category, heading}) => {
//     const [data, setData] = useState([])
//     const [loading, setLoading] = useState(false)
//     const loadingList = new Array(13).fill(null)

//     const [scroll, setScroll] = useState(0)
//     const scrollElement = useRef()

//     const { fetchUserAddToCartCount} = useContext(Context)

//     const handleAddCart = async(e, id) =>{
//         await addToCart(e, id)
//         fetchUserAddToCartCount()
//     }

//     const fetchData = async () => {
//         setLoading(true);
    
//         // Make sure to pass the flattened object
//         const categoryProduct = await fetchProductsByCategory( localMarket, category );
    
//         setLoading(false);
    
//         if (categoryProduct && categoryProduct.data) {
//             setData(categoryProduct.data);
//         } else {
//             setData([]); // Prevent undefined issues
//         }
//     };
    
   
//     useEffect(()=>{
//         fetchData()
     
//     }, [])

//     const scrollRight = () =>{
//         scrollElement.current.scrollLeft += 300
//     }

//     const scrollLeft = () =>{
//         scrollElement.current.scrollLeft -= 300
//     }

    
//     return (
//     <div className='container mx-auto px-4 my-6 relative'>
//         <h2 className='text-2xl font-semibold py-4'>
//             {heading}
//         </h2>

//         <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
//             <button  className='bg-white shadow-md rounded-full p-1 absolute left-0 text-2xl h-12 hidden md:block ' onClick={scrollLeft}><FaAngleLeft/></button>
//             <button  className='bg-white shadow-md rounded-full p-1 absolute right-0 hidden md:block transition-all text-2xl h-12' onClick={scrollRight}><FaAngleRight/></button>

//             {
//                 ( loading ? (
//                     loadingList.map((product, index) =>{
//                         return(
//                             <div className='flex w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-40 bg-white rounded-sm shadow'>
//                                 <div className=' h-full p-4 min-w-[120px] md-max-w-[145px] bg-slate-200 animate-pulse'>   
                               
//                                 </div>
//                                 <div className='p-4 grid gap-1 w-full'>
//                                     <h2 className='capitalize font-medium text-bold md:text-lg text-ellipsis line-clamp-1 text-black p-1 animate-pulse bg-slate-200'></h2>
//                                     <p className='capitalize text-slate-500 p-1 bg-slate-200'></p>
//                                     <div className='flex p-4 w-full gap-3 animate-pulse'>
//                                         <p className=' fon-medium bg-slate-200 p-1 w-full'></p>
//                                         <p className='line-through bg-slate-200 p-1 w-full'></p>
//                                     </div>
//                                    <button className=' text-black text-sm px-2 customMax-800:px-0.5 py-1 rounded-full animate-pulse w-full bg-slate-200'></button>
//                                 </div>
                
//                             </div>
//                         )
//                     })
//                 ):(
//                     data.map((product, index) =>{
//                         return(
//                             <Link to={"product/"+product?._id}  key={product.id || index} className='flex w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] h-40 bg-white rounded-sm shadow'>
//                                 <div className=' h-full p-4 min-w-[120px] md-max-w-[145px]'>
//                                     <img src={product.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'/>
                    
//                                 </div>
//                                 <div className='p-4 grid'>
//                                     <h2 className='capitalize font-medium text-bold md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.itemName}</h2>
//                                     <p className='capitalize text-slate-500'>{product?.category}</p>
//                                     <div className='text-orange-800'>
//                                         <p> { displayPrice( product?.price, product?.currency )}</p>
//                                     </div>
//                                    <button className='bg-orange-600 hover:bg-orange-700 text-black text-sm px-2 customMax-800:px-0.5 py-1 rounded-full' onClick={ (e) => handleAddCart(e, product?._id)}>Add to Cart</button>
//                                 </div>
                
//                             </Link>
//                         )
//                     })
//                 ))
                
//             }
//         </div>
   
       
//     </div>
//   )
// }

// export default HorizontalCardProduct


import React, { useEffect, useState } from 'react';
import { UploadProduct } from '../components/UploadProduct';
import summaryApi from '../common';
import ProductCard from '../components/ProductCard';
import EditProduct from '../components/EditProduct'; // Import EditProduct
import { useNavigate } from 'react-router-dom';

function AllProducts() {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false); // Control EditProduct modal
  const [allProduct, setAllProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product for editing
  const [page, setPage] = useState(0); // Track current page
  const [limit] = useState(10); // Limit can be a constant or set dynamically
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const navigate = useNavigate();

  // Fetch products from the server with pagination
  const fetchProducts = async (currentPage = 0) => {
    try {
      const response = await fetch(`${summaryApi.allProduct.url}?page=${currentPage}&limit=${limit}`, {
        method: summaryApi.allProduct.method,
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
      });

      const dataResponse = await response.json();

      if (dataResponse.success) {
        setAllProduct(dataResponse?.data || []);
        if (dataResponse.totalProductCount) {
          setTotalPages(Math.ceil(dataResponse.totalProductCount / limit));
        }
      } else {
        navigate('/error-fallback'); // Navigate to fallback page on error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate('/error-fallback'); // Navigate to fallback page on network or server error
    }
  };

  // Fetch products on initial load or when page changes
  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  // Handle next page
  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  // Handle previous page
  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  // Update product in the product list after editing
  const updateProductInList = (updatedProduct) => {
    setAllProduct((prevProducts) =>
      prevProducts.map((product) => (product?._id === updatedProduct?._id ? updatedProduct : product))
    );
  };
  

  // Open edit product modal with selected product
  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setOpenEditProduct(true);
  };


 



  return (
    <div>
      <div className='bg-white flex justify-between py-2 px-4 items-center'>
        <h2 className='font-bold text-lg'>All Products</h2>
        <button
          className='py-2 px-3 border-2 border-orange-500 rounded-full hover:bg-orange-600 hover:text-white transition-all'
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/* Display all products */}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 h-[calc(100vh-190px)] overflow-auto px-4'>
        {allProduct.map((product, index) => (
          <ProductCard
            data={product}
            key={index + 'allproduct'}
            updateProductInList={updateProductInList} // Pass the function down
            onEdit={() => handleEditProduct(product)} // Pass product to edit handler
          />
        ))}
      </div>

      {/* Pagination controls */}
      <div className='flex justify-center gap-4 py-4 items-center'>
        <button
          onClick={handlePrevPage}
          disabled={page === 0}
          className={`py-2 px-4 rounded ${page === 0 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
        >
          Prev
        </button>
        <span>
          Page {page + 1} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages - 1}
          className={`py-2 px-4 rounded ${page === totalPages - 1 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
        >
          Next
        </button>
      </div>

      {/* Upload product component */}
      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchProducts={fetchProducts} />
      )}

      {/* Edit product component */}
      {openEditProduct &&  selectedProduct && (
        <EditProduct
          onClose={() => setOpenEditProduct(false)}
          ProductData={selectedProduct}
          updateProductInList={updateProductInList} // Pass update function to EditProduct
        />
      )}
    </div>
  );
}

export default AllProducts;






import React from 'react'

function Footer() {
  return (
    <div className='w-full w-max-full p-6 bg-slate-900 mt-2'>
      <div className='grid grid-cols-4 gap-4 p-4 text-white w-full custom-550-grid:grid-cols-2 custom-551-950-grid:grid-cols-3'>
        
      <div>
          <h2 className='mb-4 font-bold'>About Us</h2>
          <ul className="list-none">
            <a href='privacy-policy'><li>Privacy policy</li></a>
            <a href='terms-of-service'><li>Terms of service</li></a>
            <li></li>
          </ul>
        </div>
        
        <div>
          <h2 className='mb-4 font-bold'>All about your Local</h2>
          <ul className="list-none">
            <li>Local farmers</li>
            <li>Local warriors</li>
            <li>Amazing local outfit</li>
          </ul>
        </div>

        <div>
          <h2 className='mb-4 font-bold'>About the New Industries</h2>
          <ul className="list-none">
            <li>Fashion</li>
            <li>Manufacturing</li>
            <li>Financial</li>
          </ul>
        </div>

        <div>
          <h2 className='mb-4 font-bold'>Logistics and Transportation</h2>
          <ul className="list-none">
            <li>Where to transport</li>
            <li>How many days of delivery</li>
            <li>Tips on vegetable and fresh preservation</li>
          </ul>
        </div>

        <div>
          <h2 className='mb-4 font-bold'>How to make money with us</h2>
          <ul className="list-none">
            <li>How to become a partner</li>
            <li>How to become a middleman </li>
            <li>About our affiliating marketer</li>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default Footer

import React, { useContext, useEffect, useState } from 'react';
import summaryApi from '../common';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../context';

const OtpVerification = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const {fetchUserdetails, fetchUserAddToCartCount} = useContext(Context)

   
    const handleOtpVerification = async (e) => {
        e.preventDefault();
    
        // Retrieve the token and check if it exists
        const token = localStorage.getItem("verificationToken");
       
        if (!token) {
            toast.error("Verification token not found. Please request OTP again.");
            return;
        }
    
        try {
            setLoading(true);
    
            const response = await fetch(summaryApi.otp_verification.url, {
                method: summaryApi.otp_verification.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ otp, token })
            });
    
            const result = await response.json();
            if (result.success) {
                toast.success(result.message );

               // localStorage.setItem('token', result.data.token);  // Save the new token
                localStorage.removeItem("verificationToken");

                if(result.data.role === "Seller"){
                    navigate('/admin-panel/all-products');
                    await fetchUserdetails();
                    await fetchUserAddToCartCount();
                } else {
                    navigate('/');
                    await fetchUserdetails();
                    await fetchUserAddToCartCount();
                }

            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.error("Verification failed:", error);
            toast.error("Failed to verify OTP");
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        console.log('user details fetched', fetchUserdetails())
    },[fetchUserdetails])
    
    const handleResendOtp = async () => {
        // Retrieve the token and check if it exists
        const token = localStorage.getItem("verificationToken");
        if (!token) {
            toast.error("Verification token not found. Please try to log in or register again.");
            return;
        }
    
        try {
            setResendLoading(true);
    
            const response = await fetch(summaryApi.resend_otp.url, {
                method: summaryApi.resend_otp.method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });
    
            const result = await response.json();
            if (result.success) {
                toast.success("A new OTP has been sent to your email.");
            } else if(response.status === 401){
                toast.error('your session has expired. Please login to verify email.')
                navigate('/login')
            } else {
                toast.error(result.message || "Failed to resend OTP. Please try again.");
            }
        } catch (error) {
            console.error("Failed to resend OTP:", error);
            toast.error("Failed to resend OTP.");
        } finally {
            setResendLoading(false);
        }
    };

    
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full -mt-32">
                <h1 className="text-2xl font-semibold text-center mb-4">Verify Your Email</h1>
                <p className="text-gray-600 text-center mb-6">Please enter the OTP sent to your email.</p>
                <form onSubmit={handleOtpVerification} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <button 
                        type="submit" 
                        disabled={loading} 
                        className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200 disabled:bg-indigo-400">
                        {loading ? "Verifying..." : "Verify OTP"}
                    </button>
                </form>
                <div className="text-center mt-4">
                    <button 
                        onClick={handleResendOtp} 
                        disabled={resendLoading} 
                        className="text-indigo-600 hover:underline mt-2">
                        {resendLoading ? "Resending OTP..." : "Resend OTP"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;


// import React, { useState, useEffect } from 'react';
// import summaryApi from '../common';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';

// const OtpVerification = () => {
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [otp, setOtp] = useState("");

//     // useEffect(() => {
//     //     // Retrieve the token from local storage (or another source) if needed
//     //     const storedToken = localStorage.getItem('token');
//     //     if (storedToken) {
//     //         setData((prev) => ({ ...prev, token: storedToken }));
//     //     }
//     // }, []);

//     // const handleChange = (e) => {
//     //     const { name, value } = e.target;
//     //     setData((prev) => ({ ...prev, [name]: value }));
//     // };

//     const handleOtpVerification = async (e) => {
//         e.preventDefault();

//         const token = localStorage.getItem("verificationToken");

//         try {
//             setLoading(true);
//             setError(null);

//             const response = await fetch(summaryApi.otp_verification.url, {
//                 method: summaryApi.otp_verification.method,
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({otp, token:token })
//             });

//             const result = await response.json();
//             if (result.success) {
//                 // Store JWT token for automatic login
//                 localStorage.setItem("authToken", result.token);

//                 toast.success(result.message);
//                 navigate("/home"); // Redirect to logged-in page
//                 localStorage.removeItem("verificationToken");

//             } else {
//                 toast.error(result.message);
//             }
//         } catch (error) {
//             console.error("Verification failed:", error);
//             toast.error("Failed to verify OTP");
//         }
//         finally {
//                 setLoading(false);
//             }
//         };

//     return (
//         <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
//             <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full -mt-32">
//                 <h1 className="text-2xl font-semibold text-center mb-4">Verify Your Email</h1>
//                 <p className="text-gray-600 text-center mb-6">Please enter the OTP sent to your email.</p>
//                 <form onSubmit={handleOtpVerification} className="space-y-4">
//                     <input
//                         type="text"
//                         placeholder="Enter OTP"
                        
//                         value={otp}
//                         onChange={(e) => setOtp(e.target.value)}
//                         className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                     />
//                     <button 
//                         type="submit" 
//                         disabled={loading} 
//                         className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200 disabled:bg-indigo-400">
//                         {loading ? "Verifying..." : "Verify OTP"}
//                     </button>
//                 </form>
//                 {error && <p className="text-red-500 text-center mt-4">{error}</p>}
//             </div>
//         </div>
//     );
// };

// export default OtpVerification;

import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const MarketContext = createContext();

// Provider component that wraps the whole app
export const MarketProvider = ({ children }) => {
  const [localMarket, setLocalMarket] = useState(() => localStorage.getItem('localMarket')); // Initialize from localStorage

  useEffect(() => {
    // Double-check for localMarket in localStorage in case it's not yet set
    const savedMarket = localStorage.getItem('localMarket');
    if (savedMarket && !localMarket) {
      setLocalMarket(savedMarket);
    }
  }, []);

  // Function to update market and save to localStorage
  const updateMarket = (market) => {
    setLocalMarket(market);
    localStorage.setItem('localMarket', market);
  };

  return (
    <MarketContext.Provider value={{ localMarket, updateMarket }}>
      {children}
    </MarketContext.Provider>
  );
};




import React, { useContext, useEffect, useState } from 'react';
import summaryApi from '../common';
import Context from '../context';
import displayPrice from '../helpers/FormatPrice';
import { MdDeleteForever } from "react-icons/md";
import { hasMultipleCurrencies, fetchCustomerCurrency, calculateTotal } from '../helpers/CartPriceHelper';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [customerCurrency, setCustomerCurrency] = useState(null);
    const [multipleCurrencies, setMultipleCurrencies] = useState(false);
    const context = useContext(Context);
    const loadingCart = new Array(context.cartProductCount).fill(null);
    const navigate = useNavigate();

    const fetchCartProduct = async () => {
        try {
            const response = await fetch(summaryApi.viewAddToCartProducts.url, {
                method: summaryApi.viewAddToCartProducts.method,
                credentials: 'include',
                headers: {
                    "content-type": "application/json"
                }
            });
    
            const responseData = await response.json();
    
            if (responseData?.success ) {
                setData(responseData?.data || []);
                // Handle case where cart data is empty
            }else {
                // Only navigate to error fallback if success is explicitly false
                console.error("API response indicates failure:", responseData.message);
                navigate('/error-fallback');
            }
        } catch (error) {
            console.error("Error fetching cart products:", error);
            navigate('/error-fallback');
        }
    };

    const handleLoading = async()=>{
       await fetchCartProduct()
    }

    useEffect(() => {
        setLoading(true);
        handleLoading()
        setLoading(false);
    }, []);

    useEffect(() => {
        const calculateTotalPrice = async () => {
            try {
                const hasMultiple = hasMultipleCurrencies(data);
                setMultipleCurrencies(hasMultiple);
    
                // Fetch the customer's local currency based on location if multiple currencies
                let currency = null;
    
                if (hasMultiple) {
                    currency = await fetchCustomerCurrency();
                    if (!currency) {
                        console.error("Currency not retrieved. Defaulting to USD.");
                        currency = 'USD'; // Fallback to a default currency
                    }
                } else {
                    currency = data[0]?.productId?.currency;
                }
    
                setCustomerCurrency(currency);
    
                const total = await calculateTotal(data, currency);
                setTotalPrice(total);
            } catch (error) {
                console.error("Error calculating total price:", error);
                navigate('/error-fallback');
            }
        };
    
        if (data.length > 0) {
            calculateTotalPrice();
        }
    }, [data]);
    
    

    const increaseQTY = async (id, qty) => {
        try{
            const response = await fetch(summaryApi.updateCartProducts.url, {
                method: summaryApi.updateCartProducts.method,
                credentials: "include",
                headers: {
                    "content-type": 'application/json',
                },
                body: JSON.stringify({
                    _id: id,
                    quantity: qty + 1
                })
            });
    
            const responseData = await response.json();
            if (responseData.success) {
                fetchCartProduct();
            } else {
                console.error("Failed to update cart:", responseData.message);
                navigate('/error-fallback');
            }
        }catch(error){
            console.error("Error to increase products:", error);
            navigate('/error-fallback');
        }
       
    };

    const decreaseQTY = async (id, qty) => {
        try{
            if (qty >= 2) {
                const response = await fetch(summaryApi.updateCartProducts.url, {
                    method: summaryApi.updateCartProducts.method,
                    credentials: "include",
                    headers: {
                        "content-type": 'application/json',
                    },
                    body: JSON.stringify({
                        _id: id,
                        quantity: qty - 1
                    })
                });
    
                const responseData = await response.json();
                if (responseData.success) {
                    fetchCartProduct();
                } else {
                    console.error("Failed to update cart:", responseData.message);
                    navigate('/error-fallback');
                }
            }
        }catch(error){
            console.error("Error to decrease product:", error);
            navigate('/error-fallback');
        }
        
    };

    const deleteCartProduct = async (id) => {
        try{
            const response = await fetch(summaryApi.deleteCartProducts.url, {
                method: summaryApi.deleteCartProducts.method,
                credentials: "include",
                headers: {
                    "content-type": 'application/json',
                },
                body: JSON.stringify({ _id: id })
            });
    
            const responseData = await response.json();
    
            if (responseData?.success) {
                fetchCartProduct();
                context.fetchUserAddToCartCount();
            } else {
                console.error("Failed to delete product:", responseData.message);
                navigate('/error-fallback');
            }
        }catch(error){
            console.error("Error to delete cart:", error);
            navigate('/error-fallback');
        }
       
    };

    const totalQty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);

    return (
        <div className='container customMin-300:max-w-full mx-auto customMax-800:mt-11'>
            <div className='text-center mt-3'>
                {data.length === 0 && !loading && (
                    <p className=' bg-white p-4'> No product in cart</p>
                )}
            </div>

            <div className='flex flex-col md:flex-row gap-10 md:justify-between p-4'>
                {/* View cart products */}
                <div className='w-full max-w-3xl'>
                    {loading ? (
                        loadingCart.map((el, index) => (
                            <div key={index + "Add to Cart Loading..."} className='w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded'>
                            </div>
                        ))
                    ) : (
                        data.map((product, index) => (
                            <div key={product?._id + "Add to Cart Loading..." || index} className='w-full bg-white max-h-36 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]'>
                                <div className='bg-slate-200 w-32 h-36'>
                                    <img src={product?.productId?.productImage[0]} alt={product.itemName} className='w-full h-full object-scale-down mix-blend-multiply' />
                                </div>
                                <div className='px-4 py-2 relative'>
                                    {/* Delete product */}
                                    <div className='absolute right-0 text-orange-900 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => deleteCartProduct(product?._id)}>
                                        <MdDeleteForever />
                                    </div>
                                    <h2 className='text-lg md:text-xl text-ellipsis line-clamp-1'>{product?.productId?.itemName}</h2>
                                    <p className='capitalize text-slate-500'>{product?.productId?.category}</p>
                                    <div className='flex  justify-between customMax-450:flex-col customMax-450:'>
                                        <p className='text-orange-900 font-medium text-lg'>
                                            {displayPrice(product?.productId?.price, product?.productId?.currency)}
                                        </p>
                                        <p className='text-orange-600 font-semibold text-lg'>
                                            {displayPrice(product?.productId?.price * product?.quantity, product?.productId?.currency)}
                                        </p>
                                    </div>
                                    <div className='flex items-center gap-3 mt-1'>
                                        <button className='p-1 border border-orange-600 text-orange-600 hover:bg-orange-500 hover:text-white w-6 h-6 flex items-center justify-center rounded' onClick={() => decreaseQTY(product?._id, product?.quantity)}>-</button>
                                        <span>{product?.quantity}</span>
                                        <button className='p-1 border border-orange-600 text-orange-600 hover:bg-orange-500 hover:text-white w-6 h-6 flex items-center justify-center rounded' onClick={() => increaseQTY(product?._id, product?.quantity)}>+</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                {/* Summary */}
                <div className='mt-4 md:mt-2 w-full max-w-sm'>
                    {loading ? (
                        <div className='h-36 bg-slate-200 border-slate-300 animate-pulse'>
                        </div>
                    ) : (
                        <div className='h-36 bg-white'>
                            <h2 className='text-white bg-orange-600 px-4 py-1'>Summary</h2>
                            <div className='flex justify-between items-center px-4 gap-2 font-medium text-lg text-slate-600'>
                                <p>Quantity</p>
                                <p>{totalQty}</p>
                            </div>

                            <div className='flex justify-between items-center px-4 gap-2 font-medium text-lg text-slate-600'>
                                <p>Total Price</p>
                                {/* Only display the price if the currency is valid */}
                                <p>
                                    {multipleCurrencies && customerCurrency ? (
                                        customerCurrency ? `${displayPrice(totalPrice, customerCurrency)} (Converted)` : "Currency unavailable"
                                    ) : (
                                        data[0]?.productId?.currency ? displayPrice(totalPrice, data[0].productId.currency) : "Price unavailable"
                                    )}
                                </p>
                            </div>


                            <button className='bg-green-600 p-2 text-white w-full'>
                                Payment
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Cart;

// import React, { useState } from 'react'
// import { CiEdit } from "react-icons/ci";
// import EditProduct from './EditProduct';
// import displayPrice from '../helpers/FormatPrice';

// const ProductCard = ({
//     data,
//     fetchProducts
// }) => {
    
//     const [editProduct, setEditProduct] = useState(false)

//     const handleEditClick = () => {
//         setEditProduct(true);
//     };

//     const handleEditClose = () => {
//         setEditProduct(false);
//         fetchProducts(); // Call fetchProducts to refresh the product list after edit
//     };

    
//   return (
//     <div className=' bg-white p-4 rounded border border-white'>
//         <div className=' w-40'>

//             <div className='w-32 h-32 flex justify-center items-center'>
//                 <img 
//                 src={data?.productImage[0]}
//                 alt={data?.productImage}
//                 className='mx-auto object-fill h-full'
//                 />
//             </div>
           
//             <h1 className='text-ellipsis line-clamp-1 font-semibold' >{data?.itemName}</h1>
//             <h1 className='text-ellipsis line-clamp-1'>{data?.itemDescription}</h1>

//             <div>
//                 <div className='font-semibold'>
//                     {
//                         displayPrice( 
//                             data?.price,
//                             data?.currency
                        
//                         )
//                     }
                    
//                 </div>
//                 <div className='w-fit ml-auto bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer '
//                     onClick={handleEditClick}>
//                     <CiEdit />
//                 </div>
//             </div>
//         </div>
      
        

//         {
//             editProduct && (
//                 <EditProduct 
//                 ProductData={data}
//                 onClose={handleEditClose}
//                 fetchProducts={fetchProducts}
//                 />
               
//             )
//         }
//     </div>
//   )
// }

// export default ProductCard

import React, { useState } from 'react';
import { CiEdit } from "react-icons/ci";
import EditProduct from './EditProduct';
import displayPrice from '../helpers/FormatPrice';

const ProductCard = ({ data, updateProductInList }) => {
    const [editProduct, setEditProduct] = useState(false);

    const handleEditClick = () => {
        setEditProduct(true);
    };

    const handleEditClose = () => {
        setEditProduct(false);
    };

    return (
        <div className='bg-white p-4 rounded border border-white w-full max-w-md sm:max-w-full sm:px-4 md:px-0 mx-auto mb-4'>
            <div className='w-full px-2'>
                {/* Product Image */}
                <div className='w-full h-48 flex justify-center items-center'>
                    <img 
                        src={data?.productImage[0]}
                        alt={data?.itemName}
                        className='mx-auto object-contain h-full w-full rounded'
                    />
                </div>

                {/* Product Details */}
                <h1 className='mt-4 text-lg font-semibold text-ellipsis line-clamp-1'>{data?.itemName}</h1>
                <p className='text-sm text-gray-500 text-ellipsis line-clamp-1'>{data?.itemDescription}</p>

                {/* Admin Comment (only for rejected products) */}
                {data.adminComment && (
                    <p className='mt-2 text-sm text-red-600'>
                        <strong>Admin Comment:</strong> {data.adminComment}
                    </p>
                )}

                {/* Product Price and Edit Button */}
                <div className='mt-2 flex justify-between items-center'>
                    <div className='font-semibold text-xl'>
                        {displayPrice(data?.price, data?.currency)}
                    </div>
                    <div
                        className='w-fit bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer'
                        onClick={handleEditClick}
                    >
                        <CiEdit className="text-lg" />
                    </div>
                </div>
            </div>

            {/* Edit Product Modal */}
            {editProduct && (
                <EditProduct 
                    ProductData={data}
                    onClose={handleEditClose}
                    updateProductInList={updateProductInList}
                />
            )}
        </div>
    );
};

export default ProductCard;


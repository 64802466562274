import summaryApi from "../common"


const fetchProductsByCategory = async (localMarket, category, page, limit=20) => {
    try {
      
      const response = await fetch(summaryApi.ProductsByCategory.url, {
        method: summaryApi.ProductsByCategory.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          localMarket,
          category: Array.isArray(category) ? category : [category],
          page,
          limit
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const dataResponse = await response.json();
  
      // Assuming dataResponse contains { success: true, data: [...] }
      if (dataResponse.success) {
        return dataResponse;
      } else {
        return { error: true, message: 'No data available' };
      }
    } catch (error) {
      console.error('Error fetching products by category:', error);
      return { error: true, message: error.message };
    }
  };
  

  

export default fetchProductsByCategory;
const countries = [
    "United States of America",
    "United Kingdom",
    "Canada",
    "Australia",
    "Austria",
    "Germany",
    "France",
    "Italy",
    "Spain",
    // Add more countries here
];

const countryList = countries.map((country, index) => ({
    id: index + 1, // Generate a unique ID starting from 1
    label: country,
    value: country
}));

export default countryList;

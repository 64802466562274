import React, { useState } from 'react'
import ROLE from '../common/role'
import { IoCloseCircleOutline } from "react-icons/io5";
import summaryApi from '../common';
import { toast } from 'react-toastify';

function ChangeUserRole({ 
    name, 
    email, 
    role, 
    userId, 
    onClose, 
    callFunc 
}) {
    
    const [userRole, setUserRole] = useState(role);

    const handleOnChangeSelect = (e) => {
        setUserRole(e.target.value);
    };

    const updateUserRole = async () => {
        try {
            const fetchDataResponse = await fetch(summaryApi.updateUser.url, {
                method: summaryApi.updateUser.method,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId : userId,
                    role: userRole,
                    
                })
            });

            const responseData = await fetchDataResponse.json(); // Await the response to get the data

            if (responseData.success) {
                toast.success(responseData.message || "User role updated successfully!");
                onClose(); // Close the dialog after success
                callFunc(); // Fetch all users again if a callback is provided
            } else {
                toast.error(responseData.message || "Failed to update user role");
            }
            
        } catch (error) {
            console.error("Error updating user role:", error);
            toast.error("An error occurred while updating the user role.");
        }
    };

    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 w-full h-full z-10 flex justify-between items-center bg-slate-200 bg-opacity-50'>
            <div className='mx-auto bg-white shadow-md p-4 w-full max-w-sm rounded-md'>
                <button className='block ml-auto cursor-pointer' onClick={onClose}>
                    <IoCloseCircleOutline />
                </button>
                <h1 className='pb-4 text-lg font-medium'>Change User Role</h1>

                <p>Name: {name}</p>
                <p>Email: {email}</p>

                <div className='flex items-center justify-between'>
                    <p>Role:</p>
                    <select className='border px-4 py-1' 
                    value={userRole} 
                    onChange={handleOnChangeSelect}>
                        {Object.values(ROLE).map(el => (
                            <option value={el} key={el}>{el}</option>
                        ))}
                    </select>
                </div>

                <button
                    className='w-fit mx-auto block border py-1 px-3 bg-orange-600 rounded-full mt-2 text-white hover:bg-orange-700'
                    onClick={updateUserRole}
                >
                    Change Role
                </button>
            </div>
        </div>
    );
}

export default ChangeUserRole;






import React, { useContext, useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
import displayPrice from '../helpers/FormatPrice';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/AddToCart';
import Context from '../context';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const ScrollVerticalProductCard = forwardRef(({ localMarket, category, heading, className = '' }, ref) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const scrollElement = useRef();

    const { fetchUserAddToCartCount } = useContext(Context);

    const handleAddCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCartCount();
    };

    // Scroll to the left
    const scrollLeft = () => {
        if (scrollElement.current) {
            scrollElement.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    // Scroll to the right
    const scrollRight = () => {
        if (scrollElement.current) {
            scrollElement.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };


    const fetchData = useCallback(async () => {
        if (loading || !hasMore) return;
    
        setLoading(true);
        try {
            const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 4);
            
            if (categoryProduct && Array.isArray(categoryProduct?.data) && categoryProduct?.data.length > 0) {
                setData((prevData) => [...prevData, ...categoryProduct.data]);
                if (categoryProduct.data.length < 4) setHasMore(false);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [page, localMarket, category, loading, hasMore]);
    
    useEffect(() => {
        if (page === 1) {
            setData([]);
        }
        fetchData();
    }, [page, localMarket]);
    

    useImperativeHandle(ref, () => ({
        loadMore: () => setPage((prevPage) => prevPage + 1),
    }));

    useEffect(() => {
        setData([]);
        setPage(1);
        setHasMore(true);
        fetchData();
    }, [localMarket]);

    useEffect(() => {
        if (page > 1) {
            fetchData();
        }
    }, [page]);

    
    const handleScroll = useCallback(() => {
        if (!scrollElement.current) return;
        
        const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
        
        if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore && !loading) {
            console.log('Triggering loadMore...');
            setPage((prevPage) => prevPage + 1);
        }
    }, [hasMore, loading]);
    
    
    useEffect(() => {
        const currentElement = scrollElement.current;
        if (currentElement) {
            currentElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentElement) {
                currentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);
    

    return (
        <div className='container customMin-300:max-w-full w-full my-6 relative'>
            <div className={`customMax-800 ${className}`}>
                <h2 className='text-2xl font-semibold py-4 pl-4'>{heading}</h2>

                {loading && data.length === 0 ? (
                    <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none'>
                        {Array(6).fill(null).map((_, index) => (
                            <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
                                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                    <div className='bg-gray-300 w-full h-full'></div>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
                                    <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
                                    <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
                                    <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : data.length === 0 ? (
                    <div className='text-center'>
                        <p>No products found in this category.</p>
                    </div>
                ) : (
                    <div className='relative flex items-center bg-green-50 p-5 '>
                        <button
                            className='bg-white shadow-md rounded-full p-1 absolute text-4xl h-16 left-0 hidden md:block z-10'
                            onClick={scrollLeft}
                            aria-label="Scroll left"
                        >
                            <FaAngleLeft />
                        </button>

                        <button
                            className='bg-white shadow-md rounded-full text-4xl h-16 p-1 absolute right-0 hidden md:block z-10'
                            onClick={scrollRight}
                            aria-label="Scroll right"
                        >
                            <FaAngleRight />
                        </button>

                        <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
                            {data.map((product, index) => (
                                <Link to={"product/" + product?._id} key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                                    <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                        <img
                                            src={product.productImage[0]}
                                            alt={product?.itemName}
                                            className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
                                        />
                                    </div>
                                    <div className='p-4 grid gap-3'>
                                        <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
                                            {product?.itemName}
                                        </h2>
                                        <p className='capitalize text-slate-500'>{product?.category}</p>
                                        <div className='text-orange-800'>
                                            <p>{displayPrice(product?.price, product?.currency)}</p>
                                        </div>
                                        <button
                                            className=' border hover:bg-green-600 text-black text-md md:font-bold px-2 py-1 rounded-full'
                                            onClick={(e) => handleAddCart(e, product?._id)}
                                        >
                                            Add to Cart
                                        </button>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default ScrollVerticalProductCard;



// import React, { useContext, useEffect, useRef, useState } from 'react';
// import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
// import displayPrice from '../helpers/FormatPrice';
// import { Link } from 'react-router-dom';
// import addToCart from '../helpers/AddToCart';
// import Context from '../context';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

// const ScrollVerticalProductCard = ({ localMarket, category, heading, className = '' }) => {
// const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(1); // Tracks the current page for pagination
//     const [hasMore, setHasMore] = useState(true); // Tracks if there are more products to load
//     const scrollElement = useRef();

//     const { fetchUserAddToCartCount } = useContext(Context);

//     const handleAddCart = async (e, id) => {
//         await addToCart(e, id);
//         fetchUserAddToCartCount();
//     };

//     // Fetch products by category with pagination
//     const fetchData = async () => {
//         if (loading || !hasMore) return; // Prevent loading if already in progress or no more products

//         setLoading(true);
//         const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 20); // Fetch 20 items per page
//         setLoading(false);

//         if (categoryProduct && categoryProduct.data.length > 0) {
//             setData((prevData) => [...prevData, ...categoryProduct.data]); // Append new products
//             if (categoryProduct.data.length < 20) {
//                 setHasMore(false); // If fewer than 20 products are fetched, no more pages
//             }
//         } else {
//             setHasMore(false); // No more products to load
//         }
//     };

//     useEffect(() => {
//         // Reset the data, page, and hasMore state when localMarket changes
//         setData([]);
//         setPage(1); // Reset to page 1
//         setHasMore(true); // Reset pagination

//         fetchData()
//     }, [localMarket]);
    
//     useEffect(() => {
//         if (page > 1) {
//           fetchData();
//         }
//       }, [page]); // Fetch data when page or localMarket changes

//     // Detect when the user scrolls to the right end of the horizontal list
//     const handleScroll = () => {
//         if (!scrollElement.current) return;
//         const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
//         if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore) {
//             setPage((prevPage) => prevPage + 1); // Load the next page
//         }
//     };

//     useEffect(() => {
//         if (scrollElement.current) {
//             scrollElement.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (scrollElement.current) {
//                 scrollElement.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [hasMore]);

//     const scrollRight = () => {
//         scrollElement.current.scrollLeft += 300;
//     };

//     const scrollLeft = () => {
//         scrollElement.current.scrollLeft -= 300;
//     };

//          return (
//         <div className='container mx-auto px-4 my-6 relative'>
//             <div className={`customMax-800 ${className}`}>
//                 <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

//                 {loading && data.length === 0 ? (
//                     // Skeleton Loader
//                     <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none'>
//                         {Array(6).fill(null).map((_, index) => (
//                             <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
//                                 <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                     <div className='bg-gray-300 w-full h-full'></div>
//                                 </div>
//                                 <div className='p-4 grid gap-3'>
//                                     <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
//                                     <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
//                                     <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
//                                     <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : data.length === 0 ? (
//                     <div className='text-center'>
//                         <p>No products found in this category.</p>
//                     </div>
//                 ) : (
//                     <div className='relative flex items-center bg-green-50 p-5'>
//                         <button
//                             className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden md:block z-10'
//                             onClick={scrollLeft}
//                             aria-label="Scroll left"
//                         >
//                             <FaAngleLeft />
//                         </button>

//                         <button
//                             className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden md:block z-10'
//                             onClick={scrollRight}
//                             aria-label="Scroll right"
//                         >
//                             <FaAngleRight />
//                         </button>

//                         <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
//                             {data.map((product, index) => (
//                                 <Link to={"product/" + product?._id} key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
//                                     <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                         <img
//                                             src={product.productImage[0]}
//                                             alt={product?.itemName}
//                                             className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//                                         />
//                                     </div>
//                                     <div className='p-4 grid gap-3'>
//                                         <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
//                                             {product?.itemName}
//                                         </h2>
//                                         <p className='capitalize text-slate-500'>{product?.category}</p>
//                                         <div className='text-orange-800'>
//                                             <p>{displayPrice(product?.price, product?.currency)}</p>
//                                         </div>
//                                         <button
//                                             className=' border hover:bg-green-600 text-black text-md md:font-bold px-2 py-1 rounded-full'
//                                             onClick={(e) => handleAddCart(e, product?._id)}
//                                         >
//                                             Add to Cart
//                                         </button>
//                                     </div>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 )}

//             </div>
//         </div>
//     );
// }

// export default ScrollVerticalProductCard;




    //     return (
//         <div className='container mx-auto px-4 my-6 relative'>
//             <div className={`customMax-800 ${className}`}>
//                 <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

//                 {loading && data.length === 0 ? (
//                     // Skeleton Loader
//                     <div className="grid customMax-639:grid-cols-2 customMax-639:grid-rows-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-x-auto customMax-639:overflow-y-scroll customMax-639:max-h-[600px]">
//                         {Array(6).fill(null).map((_, index) => (
//                             <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
//                                 <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                     <div className='bg-gray-300 w-full h-full'></div>
//                                 </div>
//                                 <div className='p-4 grid gap-3'>
//                                     <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
//                                     <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
//                                     <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
//                                     <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : data.length === 0 ? (
//                     <div className='text-center'>
//                         <p>No products found in this category.</p>
//                     </div>
//                 ) : (
//                     <div className='relative flex items-center'>
//                         <button
//                             className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden  z-10'
//                             onClick={scrollLeft}
//                             aria-label="Scroll left"
//                         >
//                             <FaAngleLeft />
//                         </button>

//                         <button
//                             className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden z-10'
//                             onClick={scrollRight}
//                             aria-label="Scroll right"
//                         >
//                             <FaAngleRight />
//                         </button>

//                         <div className='grid w-full customMax-639:overflow-y-scroll customMax-639:max-h-[80vh] grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 scrollbar-none' ref={scrollElement}>
//                             {data.map((product, index) => (
//                                 <Link to={"product/" + product?._id} key={index} className='w-full  bg-white rounded-sm shadow'>
//                                     <div className='h-48 p-4 mb-2 overflow-hidden flex justify-center'>
//                                         <img
//                                             src={product.productImage[0]}
//                                             alt={product?.itemName}
//                                             className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//                                         />
//                                     </div>
//                                     <div className='p-4 grid gap-3'>
//                                         <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
//                                             {product?.itemName}
//                                         </h2>
//                                         <p className='capitalize text-slate-500'>{product?.category}</p>
//                                         <div className='text-orange-800'>
//                                             <p>{displayPrice(product?.price, product?.currency)}</p>
//                                         </div>
//                                         <button
//                                             className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
//                                             onClick={(e) => handleAddCart(e, product?._id)}
//                                         >
//                                             Add to Cart
//                                         </button>
//                                     </div>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 )}

//             </div>
//         </div>
//     );

// };
// import React, { useEffect, useState } from 'react'
// import { FaAngleRight } from "react-icons/fa6";
// import { FaAngleLeft } from "react-icons/fa6";
// import image1 from '../assest/banner/img1.webp'
// import image2 from '../assest/banner/img2.webp'
// import image3 from '../assest/banner/img3.jpg'
// import image4 from '../assest/banner/img4.jpg'
// import image5 from '../assest/banner/img5.webp'

// import image1Mobile from '../assest/banner/img1_mobile.jpg'
// import image2Mobile from '../assest/banner/img2_mobile.webp'
// import image3Mobile from '../assest/banner/img3_mobile.jpg'
// import image4Mobile from '../assest/banner/img4_mobile.jpg'
// import image5Mobile from '../assest/banner/img5_mobile.png'


// const BannerProduct2 = () => {
//     const [currentImage, setCurrentImage] = useState(0)
//     const [loading, setLoading] = useState(false)

//     const desktopImages =[
//         image1,
//         image2,
//         image3,
//         image4,
//         image5
//     ]



//     const mobileImages =[
//         image1Mobile,
//         image2Mobile,
//         image3Mobile,
//         image4Mobile,
//         image5Mobile
//     ]

//     const nextImage = () =>{
//         if(desktopImages.length -1 > currentImage){
//             setCurrentImage(preve => preve + 1)
//         }  
//     }

    
//     const preveImage = () =>{
//         if(currentImage != 0){
//             setCurrentImage(preve => preve - 1)
//         }  
//     }

//     useEffect(()=>{
//         const interval = setInterval(()=>{
//             if(desktopImages.length -1 > currentImage){
//                 nextImage()
//             }else {
//                 setCurrentImage(0)
//             }
//         }, 3000)
//         return ()=> clearInterval(interval)
//     }, [currentImage])

//   return (
//     <div className='container mx-0 w-full max-w-full rounded customMax-800:mt-11 '>
//         <div className='h-60 md:h-80 w-full max-w-full bg-slate-200 relative'>

//             <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
//                 <div className='flex justify-between h-11 w-full text-2xl'>
//                     <button onClick={preveImage} className='bg-white shadow-md rounded-full p-1'><FaAngleLeft/></button>
//                     <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'><FaAngleRight/></button>
//                 </div>
                
//             </div>
//             {/**desktop and tablet */}
//             <div className='hidden md:flex h-full w-full overflow-hidden'>
//                 {
//                     desktopImages.map((imageUrl, index) =>{
//                         return(
//                             <div className='w-full h-full min-w-full min-h-full transition-all' key={imageUrl} style={{transform : `translateX(-${currentImage * 100}%)`}}>
//                                 <img src={imageUrl} className='w-full h-full '/>
//                             </div>
//                         )
//                     })
//                 }
//             </div>

//             {/**mobile version */}
//             <div className='flex h-full w-full overflow-hidden md:hidden'>
//                 {
//                     mobileImages.map((imageUrl, index) =>{
//                         return(
//                             <div className='w-full h-full min-w-full min-h-full transition-all' key={imageUrl} style={{transform : `translateX(-${currentImage * 100}%)`}}>
//                                 <img src={imageUrl} className='w-full h-full object-cover'/>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
            
            
//         </div>
//     </div>
    
//   )
// }

// export default BannerProduct2

import React, { useEffect, useState } from 'react'
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import image1 from '../assest/banner/img1.webp'
import image2 from '../assest/banner/img2.webp'
import image3 from '../assest/banner/img3.jpg'
import image4 from '../assest/banner/img4.jpg'
import image5 from '../assest/banner/img5.webp'

import image1Mobile from '../assest/banner/img1_mobile.jpg'
import image2Mobile from '../assest/banner/img2_mobile.webp'
import image3Mobile from '../assest/banner/img3_mobile.jpg'
import image4Mobile from '../assest/banner/img4_mobile.jpg'
import image5Mobile from '../assest/banner/img5_mobile.png'

const BannerProduct2 = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [loading, setLoading] = useState(true); // Manage loading state

    const desktopImages = [
        image1,
        image2,
        image3,
        image4,
        image5
    ];

    const mobileImages = [
        image1Mobile,
        image2Mobile,
        image3Mobile,
        image4Mobile,
        image5Mobile
    ];

    const nextImage = () => {
        if (desktopImages.length - 1 > currentImage) {
            setCurrentImage(prev => prev + 1);
        }
    };

    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage(prev => prev - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (desktopImages.length - 1 > currentImage) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [currentImage]);

    const handleImageLoad = () => {
        setLoading(false); // Image has loaded, stop the skeleton
    };

    return (
        <div className='container mx-0 w-full max-w-full rounded customMax-800:mt-11'>
            <div className='h-60 md:h-80 w-full max-w-full bg-slate-200 relative'>
                {/* Button controls for desktop and tablet */}
                <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
                    <div className='flex justify-between h-11 w-full text-2xl'>
                        <button onClick={prevImage} className='bg-white shadow-md rounded-full p-1'>
                            <FaAngleLeft />
                        </button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'>
                            <FaAngleRight />
                        </button>
                    </div>
                </div>

                {/* Desktop and tablet images */}
                <div className='hidden md:flex h-full w-full overflow-hidden'>
                    {
                        desktopImages.map((imageUrl, index) => (
                            <div
                                className='w-full h-full min-w-full min-h-full transition-all'
                                key={imageUrl}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                {loading && (
                                    <div className="absolute inset-0 bg-gray-300 animate-pulse"></div> // Skeleton
                                )}
                                <img
                                    src={imageUrl}
                                    className='w-full h-full'
                                    onLoad={handleImageLoad} // Image loaded
                                    style={{ display: loading ? 'none' : 'block' }} // Hide until loaded
                                    alt={`Banner ${index + 1}`}
                                />
                            </div>
                        ))
                    }
                </div>

                {/* Mobile version */}
                <div className='flex h-full w-full overflow-hidden md:hidden'>
                    {
                        mobileImages.map((imageUrl, index) => (
                            <div
                                className='w-full h-full min-w-full min-h-full transition-all'
                                key={imageUrl}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                {loading && (
                                    <div className="absolute inset-0 bg-gray-300 animate-pulse"></div> // Skeleton for mobile
                                )}
                                <img
                                    src={imageUrl}
                                    className='w-full h-full object-cover'
                                    onLoad={handleImageLoad} // Image loaded
                                    style={{ display: loading ? 'none' : 'block' }} // Hide until loaded
                                    alt={`Mobile Banner ${index + 1}`}
                                />
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

export default BannerProduct2;

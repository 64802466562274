import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import summaryApi from '../common';
import ReviewProductCard from '../components/ReviewProductCard';

const PendingApprovalProduct = () => {
    const [allProduct, setAllProduct] = useState([]);
    const [page, setPage] = useState(0);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    // Fetch products from the server with pagination
    const fetchPendingProducts = async (currentPage = 0) => {
        try {
            const response = await fetch(
                `${summaryApi.pendingApprovalProd.url}?page=${currentPage}&limit=${limit}`,
                {
                    method: summaryApi.pendingApprovalProd.method,
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                }
            );

            const dataResponse = await response.json();

            if (dataResponse.success) {
                setAllProduct(dataResponse.data || []);
                setTotalPages(Math.ceil(dataResponse.totalProductCount / limit));
            } else {
                if (dataResponse.status >= 500) navigate('/error-fallback');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            navigate('/error-fallback');
        }
    };

    // Fetch products on initial load or when page changes
    useEffect(() => {
        fetchPendingProducts(page);
    }, [page]);

    // Handle pagination
    const handleNextPage = () => {
        if (page < totalPages - 1) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 0) setPage(page - 1);
    };

    // Update product in the list after admin action
    // Parent Component: PendingApprovalProducts
    const updateProductInList = (productId) => {
        setAllProduct((prevProducts) => {
            const updatedProducts = prevProducts.filter((product) => product._id !== productId);
            return updatedProducts;
        });       
    };


    return (
        <div>
            <div className='bg-white flex justify-between py-2 px-4 items-center'>
                <h2 className='font-bold text-lg'>Pending Approval Products</h2>
            </div>

            {/* Display all products */}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-4 h-[calc(100vh-190px)] overflow-auto px-4'>
                {allProduct.map((product, index) => (
                    <ReviewProductCard
                        key={index + 'pendingApproval'}
                        data={product}
                        updateProductInList={updateProductInList}
                    />
                ))}
            </div>

            {/* Pagination controls */}
            <div className='flex justify-center gap-4 py-4 items-center'>
                <button
                    onClick={handlePrevPage}
                    disabled={page === 0}
                    className={`py-2 px-4 rounded ${page === 0 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
                >
                    Prev
                </button>
                <span>
                    Page {page + 1} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={page === totalPages - 1}
                    className={`py-2 px-4 rounded ${page === totalPages - 1 ? 'bg-gray-200' : 'bg-orange-500 text-white'}`}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PendingApprovalProduct;

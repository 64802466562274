// ChooseUserRole component
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import summaryApi from '../common';



function ChooseUserRole({ userId, onRoleSelected }) {
    const [userRole, setUserRole] = useState('');
  
    const handleRoleSubmit = async () => {
        if (!userRole) {
            toast.error("Please select a role.");
            return;
        }

        try {
            const response = await fetch(summaryApi.updateUserRole.url, {
                method: summaryApi.updateUserRole.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, role: userRole }),
            });

            const responseData = await response.json();
            console.log("API Response for Role Update:", responseData);
            if (responseData.success) {
                console.log("Selected Role:", responseData.role);
                toast.success("Role selected successfully!");
                onRoleSelected(userRole); // Pass the selected role
            } else {
                toast.error("Failed to select role.");
            }
        } catch (error) {
            console.error("Error selecting role:", error);
        }
    };

    return (
        <div className="fixed w-full max-w-full inset-0 bg-gray-800 bg-opacity-10 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg max-w-md ">
                <h2>Please Choose Your Role</h2>
                <select
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value)}
                    className="border-2 px-4 py-2 mt-2 max-w-full mr-2"
                >
                    <option value="">Select a role</option>
                    <option value="Customer">Customer</option>
                    <option value="Seller">Seller</option>
                </select>
                <button
                    onClick={handleRoleSubmit}
                    className="mt-4 bg-orange-600 text-white py-2 px-4 rounded hover:bg-orange-700 ml-2"
                >
                    Confirm Role
                </button>
            </div>
        </div>
    );
}

export default ChooseUserRole;


import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

const PrivacyPolicyDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h5" className="text-primary font-semibold">
          Privacy Policy
        </Typography>
      </DialogTitle>
      <DialogContent className="space-y-4 p-4">
        <Typography variant="body1" className="text-gray-700">
          Welcome to our platform! Please read our privacy policy carefully. We store a token
          in your browser's localStorage to authenticate your login session and remember your
          marketing preferences.
        </Typography>
        <Typography variant="h6" className="text-primary font-medium">
          What We Collect
        </Typography>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          <li>Authentication token for secure login.</li>
          <li>Marketing preferences for personalized offers.</li>
        </ul>
        <Typography variant="h6" className="text-primary font-medium">
          How We Protect Your Data
        </Typography>
        <Typography variant="body1" className="text-gray-700">
          We implement secure HTTPS connections, restricted data access, and regular audits
          to ensure your data's safety.
        </Typography>
        <Typography variant="body1" className="text-gray-700">
          For more details, please contact us at <span className="text-blue-500">inquiry@homemarket2u.com</span>.
        </Typography>
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={onClose}
          variant="contained"
          className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg px-4 py-2"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicyDialog;


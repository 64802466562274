import React, { useContext, forwardRef } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayPrice from '../helpers/FormatPrice'
import Context from '../context'
import addToCart from '../helpers/AddToCart'
import { Link } from 'react-router-dom'

const AllVerticalProductCard = forwardRef(({ loading, data = [] }, ref) => {
    const loadingList = new Array(13).fill(null)
    const { fetchUserAddToCartCount } = useContext(Context)

    const handleAddToCart = async(e, id) => {
       await addToCart(e, id)
       fetchUserAddToCartCount();
    }

    return (
        <div className='flex flex-wrap md:grid grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-between md:gap-4 gap-[35px] overflow-x-scroll scrollbar-none transition-all customMax-525:flex customMax-525:flex-col customMax-525:border-2 customMax-525:max-w-full customMax-525:w-full items-center customMax-525:p-2'>
        {
            loading ? (
                loadingList.map((_, index) => (
                    <div className='w-full min-w-[280px]  md:min-w-[320px] max-w-[280px] md:max-w-[320px]  bg-white rounded-sm shadow ' key={index}>
                        <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse' />
                        <div className='p-4 grid gap-3'>
                            <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                            <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200  py-2'></p>
                            <div className='flex gap-3'>
                                <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                                <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                            </div>
                            <button className='text-sm  text-white px-3  rounded-full bg-slate-200  py-2 animate-pulse'></button>
                        </div>
                    </div>
                ))
            ) : (
                data.map((product, index) => (
                    <Link to={`/product/${product?._id}`} 
                        className='w-full min-w-[280px]  md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow customMax-525:max-w-[340px]' 
                        onClick={scrollTop} 
                        ref={index === data.length - 1 ? ref : null} // Assign ref to the last item
                        key={product._id}>
                        <div className='bg-slate-150 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center '>
                            <img src={product?.productImage[0]} alt='product image' className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
                        </div>
                        <div className='p-4 grid gap-1.5'>
                            <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.itemName}</h2>
                            <p className='capitalize text-slate-500'>{product?.category}</p>
                            <div className='flex gap-3'>
                                <p>{displayPrice(product?.price, product?.currency)}</p>
                            </div>
                            <button className='bg-orange-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full' 
                            onClick={(e) => handleAddToCart(e, product?._id)}>Add to Cart
                            </button>
                        </div>
                    </Link>
                ))
            )
        }
        </div>
    )
});

export default AllVerticalProductCard;




// import React, { useContext } from 'react'
// import scrollTop from '../helpers/scrollTop'
// import displayPrice from '../helpers/FormatPrice'
// import Context from '../context'
// import addToCart from '../helpers/AddToCart'
// import { Link } from 'react-router-dom'

// const AllVerticalProductCard = ({loading,data = [],  localMarket}, ref,) => {
//     const loadingList = new Array(13).fill(null)
//     const { fetchUserAddToCartCount } = useContext(Context)

//     const handleAddToCart = async(e,id)=>{
//        await addToCart(e,id)
//        fetchUserAddToCartCount();
//     }

//   return (
//     //<div className='grid grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-between md:gap-4 overflow-x-scroll scrollbar-none transition-all customMax-525:gap-6 customMax-525:bottom-5 customMax-525:border-white customMax-525:mx-2 customMax-525:p-4'>
//     <div className='flex flex-wrap md:grid grid-cols-[repeat(auto-fit,minmax(260px,300px))] justify-center md:justify-between md:gap-4 gap-[35px] overflow-x-scroll scrollbar-none transition-all customMax-525:flex customMax-525:flex-col customMax-525:border-2 customMax-525:max-w-full customMax-525:w-full items-center customMax-525:p-2'>
//     {

//          loading ? (
//              loadingList.map((product,index)=>{
//                  return(
//                      <div className='w-full min-w-[280px]  md:min-w-[320px] max-w-[280px] md:max-w-[320px]  bg-white rounded-sm shadow '>
//                          <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'>
//                          </div>
//                          <div className='p-4 grid gap-3'>
//                              <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
//                              <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200  py-2'></p>
//                              <div className='flex gap-3'>
//                                  <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
//                                  <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
//                              </div>
//                              <button className='text-sm  text-white px-3  rounded-full bg-slate-200  py-2 animate-pulse'></button>
//                          </div>
//                      </div>
//                  )
//              })
//          ) : (
//              data.map((product,index)=>{
//                  return(
//                      <Link to={"/product/"+product?._id} 
//                         className='w-full min-w-[280px]  md:min-w-[300px] max-w-[280px] md:max-w-[300px] bg-white rounded-sm shadow customMax-525:max-w-[340px]' 
//                         onClick={scrollTop}>
//                          <div className='bg-slate-150 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center '>
//                              <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'/>
//                          </div>
//                          <div className='p-4 grid gap-1.5'>
//                              <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.itemName}</h2>
//                              <p className='capitalize text-slate-500'>{product?.category}</p>
//                              <div className='flex gap-3'>
//                                 <p>{displayPrice(product?.price, product?.currency)}</p>
//                              </div>
//                              <button className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full' 
//                              onClick={(e)=>handleAddToCart(e,product?._id)}>Add to Cart
//                              </button>
//                          </div>
//                      </Link>
//                  )
//              })
//          )
         
//      }
//     </div>
//   )
// }

// export default AllVerticalProductCard